import React, { useEffect, useState } from "react";
import { availableTargetingTagsAtom, targetingTagsAtom } from "src/atoms/targeting-tags";
import "./NotificationsBlock.scss";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  MenuItem,
  RadioGroup,
  FormGroup,
  Checkbox,
  Dialog,
  Tooltip,
  Button,
  Stack,
  Typography,
  Divider,
  Select,
  Chip,
} from "@mui/material";
import { useAtom } from "jotai";
import { TargetingTag } from "../../../../types/TargetingTag";
import { NotificationSchedule, PREDEFINED_TAGS, NotificationsTagTypeEnum } from "../../../../types/Survey";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import SurveysService from "../../../../services/api/surveysService";
import { EmailTemplate } from "../../../../types/Email";
import EmailTemplateSelector from "src/components/EmailTemplateSelector/EmailTemplateSelector";

import CustomDateTimePicker from "src/components/DateTimePicker/DateTimePicker";
import TZPicker from "src/components/TZPicker/TZPicker";
import { PickersTimezone } from "@mui/x-date-pickers";
import { utmCampaignTargetingTagsAtom } from "../components/RightColumnFields";
import { allAvailableUtmTagsAtom } from "src/atoms/utm-tags";
import { umtAllTagkey } from "src/constants/survey";
import { getNotificationTargetingTags, santizeUtmTag } from "src/helpers/surveyHelper";

type PropTypes = {
  notificationsLocked: boolean;
  status: boolean;
  updateSurvey: (manualStatus?: boolean) => void;
  surveyStartTime?: string | null;
  selectedNotificationSchedule?: NotificationSchedule;
  onRefreshSurvey?: () => void;
};

const customZones = {
  now: "Now",
  start: "On survey start",
};

const NOTIFICATION_TEMPLATE_PARAMETERS = ["points", "loginUrl", "firstName", "lastName", "userId"];

const NotificationsBlock = ({
  notificationsLocked,
  surveyStartTime,
  selectedNotificationSchedule: notificationSchedule,
  onRefreshSurvey,
  updateSurvey,
  status,
}: PropTypes) => {
  const { id: surveyId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [singleEmailToNotify, setSinglEmailToNotify] = React.useState<string | undefined>(
    notificationSchedule?.scheduleOptions.singleEmailToNotify
  );

  const [sendDisabled, setSendDisabled] = React.useState<boolean>(true);
  const [sendToAllDisabled, setSendToAllDisabled] = React.useState<boolean>(false);
  const [sendToTagDisabled, setSendToTagDisabled] = React.useState<boolean>(false);
  const [emailTemplateSelectionValid, setEmailTemplateSelectionValid] = React.useState<boolean>(false);
  const [forceDisabled, setForceDisabled] = React.useState<boolean>(false);
  const [timezone, setTimeZone] = useState<PickersTimezone>("America/Los_Angeles");
  const [scheduledAt, setScheduledAt] = useState<Date | undefined>(
    notificationSchedule?.scheduledAt ? new Date(notificationSchedule.scheduledAt) : undefined
  );
  const [disableScheduler, setDisableScheduler] = useState<boolean>(false);

  const [notificationsTagType, setNotificationsTagType] = useState<NotificationsTagTypeEnum>(
    notificationSchedule?.scheduleOptions?.notificationsTagType || NotificationsTagTypeEnum.ALL
  );
  const [notificationTags, setNotificationTags] = useState<string[]>(
    getNotificationTargetingTags(notificationSchedule?.scheduleOptions)
  );
  const [forceNotifications, setForceNotifications] = useState<boolean>(
    notificationSchedule?.scheduleOptions?.forceNotifications || false
  );
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate>();

  const [allTargetingTags] = useAtom(availableTargetingTagsAtom);
  const [availableTargetingTags, setAvailableTargetingTags] = useState<TargetingTag[]>([]);
  const [allAvailableUtmTags] = useAtom(allAvailableUtmTagsAtom);
  const [targetedUtmTags, setTargetedUtmTags] = useState<TargetingTag[]>([]);

  const [targetingTags] = useAtom(targetingTagsAtom);
  const [utmCampaignTargetingTags] = useAtom(utmCampaignTargetingTagsAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);
  const [updateStatusActive, setUpdateStatusActive] = useState<boolean>(false);

  const statusDialogClose = () => {
    setStatusDialogOpen(false);
  };

  useEffect(() => {
    const allTagSelected = !!targetingTags.find((targetingTag) => targetingTag.tag === PREDEFINED_TAGS.allForSurvey);

    const availableTags = allTagSelected
      ? allTargetingTags.filter((targetingTag) => targetingTag.surveyId)
      : targetingTags;

    setAvailableTargetingTags(availableTags);
  }, [targetingTags, allTargetingTags]);

  useEffect(() => {
    const tags = allAvailableUtmTags
      .filter((tag) => utmCampaignTargetingTags?.includes(String(tag._id)))
      .map((tag) => {
        return {
          ...tag,
          tag: santizeUtmTag(tag),
        };
      });
    if (utmCampaignTargetingTags.includes(umtAllTagkey)) {
      tags.push({ tag: `${umtAllTagkey}`, _id: umtAllTagkey });
    }
    setTargetedUtmTags(tags);
  }, [utmCampaignTargetingTags, allAvailableUtmTags]);

  useEffect(() => {
    const tagInvalid = notificationsTagType === NotificationsTagTypeEnum.TAG && !notificationTags?.length;
    setSendDisabled(isLoading || notificationsLocked || tagInvalid || !emailTemplateSelectionValid);
  }, [isLoading, notificationsLocked, notificationsTagType, notificationTags, emailTemplateSelectionValid]);

  const handleSendNotificationsButton = () => {
    if (status) {
      const askForConfirmation = window.confirm("Are you sure you want to send the notifications?");
      if (!askForConfirmation) return;

      sendNotifications(singleEmailToNotify);

      return;
    }

    setStatusDialogOpen(true);
  };

  const getMinDate = () => {
    const date = new Date();
    return date;
  };

  useEffect(() => {
    if (notificationSchedule?.scheduleOptions) {
      setSinglEmailToNotify(notificationSchedule?.scheduleOptions.singleEmailToNotify || "");
      setScheduledAt(new Date(notificationSchedule.scheduledAt));
      setNotificationsTagType(notificationSchedule?.scheduleOptions?.notificationsTagType || "");
      setNotificationTags(getNotificationTargetingTags(notificationSchedule?.scheduleOptions));
      setSelectedEmailTemplate(notificationSchedule?.scheduleOptions?.emailTemplate);
      setForceNotifications(notificationSchedule?.scheduleOptions?.forceNotifications || false);
    }
  }, [notificationSchedule]);

  useEffect(() => {
    if (timezone === customZones.now) {
      setDisableScheduler(true);
      setScheduledAt(undefined);
    } else if (timezone === customZones.start) {
      setDisableScheduler(true);
      setScheduledAt(surveyStartTime ? new Date(surveyStartTime) : undefined);
    } else {
      setDisableScheduler(false);
    }
  }, [timezone]);

  const statusDialogProceed = () => {
    if (updateStatusActive) {
      updateSurvey(true);
    }

    sendNotifications(singleEmailToNotify);
    setStatusDialogOpen(false);
  };

  const setSingleEmail = (email: string) => {
    setSinglEmailToNotify(email);

    setSendToAllDisabled(email !== "");
    setSendToTagDisabled(email !== "");
    setForceDisabled(email !== "");
  };

  const sendNotifications = async (singleEmailToNotify?: string) => {
    if (!surveyId) return;

    setIsLoading(true);

    if (!selectedEmailTemplate) return;

    try {
      const res = await SurveysService.newSurveyNotification(surveyId, {
        emailTemplate: selectedEmailTemplate,
        singleEmailToNotify: singleEmailToNotify || undefined,
        notificationsTagType,
        notificationTags,
        forceNotifications,
        scheduledAt,
        scheduleId: notificationSchedule?._id,
      });
      enqueueSnackbar(res.data.message, { variant: "success" });
      if (scheduledAt && typeof onRefreshSurvey === "function") {
        onRefreshSurvey();
      }
    } catch (err: any) {
      enqueueSnackbar(err.response?.data?.message || err.message, { variant: "error" });
    } finally {
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
    }

    setIsLoading(false);
  };

  const handleTagsChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setNotificationTags(typeof value === "string" ? value.split(",") : value);
  };

  const renderValue = (selected: string[]) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((value) => {
        const isUtmTag = targetedUtmTags.some((tag) => tag._id === value);
        const tag = isUtmTag
          ? targetedUtmTags.find((tag) => tag._id === value)
          : availableTargetingTags.find((tag) => tag._id === value);

        return (
          <Chip
            key={value}
            label={tag?.tag || value}
            sx={{
              backgroundColor: isUtmTag ? "primary.light" : "default",
              "& .MuiChip-label": {
                color: isUtmTag ? "primary.contrastText" : "inherit",
              },
            }}
          />
        );
      })}
    </Box>
  );
  const renderMenuItems = () => {
    const menuItems = [];

    if (availableTargetingTags?.length > 0) {
      menuItems.push(
        <MenuItem key="targeting-header" sx={{ py: 1 }} disabled>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            Targeting Tags
          </Typography>
        </MenuItem>,
        ...availableTargetingTags.map((tag) => (
          <MenuItem key={tag._id} value={tag._id}>
            <Checkbox checked={notificationTags.indexOf(tag._id) > -1} />
            <Typography>
              {tag.tag}
              {tag.surveyId ? "" : " 🌐"}
            </Typography>
          </MenuItem>
        ))
      );
    }

    if (targetedUtmTags?.length > 0) {
      if (availableTargetingTags?.length > 0) {
        menuItems.push(
          <MenuItem key="divider" sx={{ py: 0 }}>
            <Divider sx={{ width: "100%" }} />
          </MenuItem>
        );
      }

      menuItems.push(
        <MenuItem key="utm-header" sx={{ py: 1 }} disabled>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            UTM Tags
          </Typography>
        </MenuItem>,
        ...targetedUtmTags.map((tag) => (
          <MenuItem
            key={tag._id}
            value={tag._id}
            sx={{
              "&:hover": { backgroundColor: "primary.lighter" },
              backgroundColor: notificationTags.includes(tag._id) ? "primary.lighter" : "inherit",
            }}
          >
            <Checkbox checked={notificationTags.indexOf(tag._id) > -1} />
            <Typography color="primary">{tag.tag}</Typography>
          </MenuItem>
        ))
      );
    }

    return menuItems;
  };

  return (
    <div>
      <h3 className="target-container__title">Send Notifications to community members</h3>
      <div className="last-container">
        <p className="reminder">
          NOTE: emails will be sent every time "Send notifications" is clicked. If there are doubts about some
          notifications being sent, please check the Email Activity section in Sendgrid's Dashboard.
          <br />
          <br />
          Only the existent and active users that did not already complete this survey will receive the email.
          <br />
          <br />
          <strong>
            Also, remember to update the Targeting Information and press the above "Save" button before sending the
            notifications.
          </strong>
        </p>
      </div>

      <Box sx={{ marginBottom: "1rem" }}>
        <EmailTemplateSelector
          key={notificationSchedule?.scheduleOptions?.emailTemplate?.id}
          selectedTemplateId={notificationSchedule?.scheduleOptions?.emailTemplate?.id}
          onTemplateSelected={(val) => setSelectedEmailTemplate(val)}
          isReady={(val) => {
            setEmailTemplateSelectionValid(val);
          }}
          expectedParams={NOTIFICATION_TEMPLATE_PARAMETERS}
        />
      </Box>

      <Box sx={{ display: "flex", columnGap: "10px", marginBottom: "1rem" }}>
        <div className="target-container__target-single-user__text target-container__target-single-user__name">
          Test email
        </div>
        <FormControl>
          <TextField
            size="small"
            placeholder="Non-admin Verasight email"
            onChange={(event) => setSingleEmail(event.target.value)}
            value={singleEmailToNotify}
            type="email"
          />
        </FormControl>
      </Box>

      <RadioGroup value={notificationsTagType} sx={{ marginBottom: "1rem" }}>
        <Box>
          <FormControlLabel
            onChange={() => setNotificationsTagType(NotificationsTagTypeEnum.ALL)}
            value={NotificationsTagTypeEnum.ALL}
            control={<Radio color="primary" />}
            label="Send to all Targeted users"
            disabled={sendToAllDisabled}
          />
        </Box>
        {(availableTargetingTags?.length || targetedUtmTags?.length) && (
          <Box>
            <FormControlLabel
              onChange={() => setNotificationsTagType(NotificationsTagTypeEnum.TAG)}
              value={NotificationsTagTypeEnum.TAG}
              control={<Radio color="primary" />}
              label="Send to tags"
              disabled={sendToTagDisabled}
            />
            <Box sx={{ pl: 4 }}>
              <FormControl fullWidth disabled={notificationsTagType !== NotificationsTagTypeEnum.TAG}>
                <Select
                  multiple
                  value={notificationTags}
                  onChange={handleTagsChange}
                  renderValue={renderValue}
                  size="small"
                >
                  {renderMenuItems()}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </RadioGroup>
      <FormGroup sx={{ marginBottom: "1rem" }}>
        <Tooltip title="Force the sending of notifications to all those who have been notified, and those who have not, but have not completed the survey.">
          <FormControlLabel
            className="force-notifications"
            sx={{ marginLeft: 0, width: "max-content" }}
            label="Force?"
            control={
              <Checkbox checked={forceNotifications} onChange={(e) => setForceNotifications(e.target.checked)} />
            }
            disabled={forceDisabled}
          />
        </Tooltip>
      </FormGroup>
      <Box sx={{ display: "flex", columnGap: "10px" }}>
        <Stack spacing={2}>
          <TZPicker customZones={Object.values(customZones)} onTZChange={setTimeZone} />
          <div className="date-picker-lable input-label">Schedule Notification</div>
          <CustomDateTimePicker
            minDate={getMinDate()}
            disabled={disableScheduler}
            currentTimezone={Object.values(customZones).includes(timezone) ? "system" : timezone}
            value={scheduledAt ? new Date(scheduledAt) : null}
            setValue={(newValue: any) => {
              setScheduledAt(newValue);
            }}
          />
        </Stack>
      </Box>
      <div
        style={{
          marginTop: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          type="button"
          className={`last-container__button ${sendDisabled ? "btn-disabled" : ""}`}
          onClick={handleSendNotificationsButton}
          disabled={sendDisabled}
        >
          <Tooltip
            title={
              sendDisabled
                ? "Survey targeting details have changed. Please save the survey to enable sending the notifications."
                : ""
            }
          >
            <span>
              {" "}
              {notificationSchedule?._id
                ? "Update Schedule"
                : scheduledAt
                ? "Schedule Notification"
                : "Send Notifications"}
            </span>
          </Tooltip>
        </button>
      </div>

      <Dialog onClose={statusDialogClose} open={statusDialogOpen}>
        <div className="status-dialog">
          <p>The survey is currently inactive.</p>

          <div className="status-dialog__actions">
            <FormGroup className="status-dialog__checkbox">
              <FormControlLabel
                control={
                  <Checkbox value={updateStatusActive} onChange={(e) => setUpdateStatusActive(e.target.checked)} />
                }
                label="Change survey to Active?"
                data-cy="set-active-checkbox"
              />
            </FormGroup>
            <div className="status-dialog__buttons">
              <Button variant="contained" onClick={statusDialogProceed} data-cy="set-active-proceed-button">
                Proceed
              </Button>
              <Button variant="outlined" onClick={statusDialogClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NotificationsBlock;
