import { Box, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type Props<T> = {
  label?: string;
  items: Array<T>;
  setSelectedItems: (arg: Array<T>) => void;
  selectedItems: Array<T>;
};

const SelectionFilter = <T extends string>({ label, items, selectedItems, setSelectedItems }: Props<T>) => {
  return (
    <Box>
      <p className="option-title">{label ?? "Select Any"}</p>
      {items.map((item) => {
        const selected = selectedItems.includes(item);
        const handleCheck = () => {
          if (selected) {
            setSelectedItems(selectedItems.filter((select) => select !== item));
            return;
          }
          setSelectedItems(selectedItems.concat([item]));
        };
        return (
          <ListItem key={item} disablePadding sx={{ height: "35px" }}>
            <ListItemButton onClick={() => handleCheck()} dense sx={{ height: "100%" }}>
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox edge="start" checked={selected} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </Box>
  );
};

export default SelectionFilter;
