import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import BarChartIcon from "@mui/icons-material/BarChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import SurveysService from "../../../services/api/surveysService";
import VeraButton, { MainButton } from "../../../components/VeraButton";
import { convertDatetimeStringToReadable } from "../../../helpers/date";
import ListItemIcon from "@mui/material/ListItemIcon";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Dialog, DialogTitle, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import "./SurveysList.scss";
import pinIcon from "../../../assets/icon-pin.svg";
import cloneIcon from "../../../assets/icon-clone.svg";
import ellipsisIcon from "../../../assets/ellipsis.svg";
import successIcon from "../../../assets/icon-success.svg";
import QuestionDialog from "../../../components/QuestionDialog/QuestionDialog";
import { useSnackbar } from "notistack";
import SurveysFilter, {
  StatusFilterEnum,
  createdByRoleAtom,
  searchValueAtom,
  selectedCategoriesAtom,
  sourceFilterAtom,
  statusFilterAtom,
} from "./SurveysFilter";
import { atom, useAtom } from "jotai";
import { SurveySource, SurveyType, SurveysSyncSource } from "../../../types/Survey";
import SurveysSyncProgress from "./SurveysSyncProgress";
import useSortableData from "../../../hooks/useSortableData";
import usePagination from "../../../hooks/usePagination";
import TableHeadSortable from "../../../components/common/TableHeadSortable";
import { AuthContext } from "src/contexts/authContext";
import { UserRoles } from "src/types/User";
import { getPathBase } from "src/helpers/urlHelper";
import { AppPaths, SearchParams } from "src/constants/url";
import Loader from "src/components/Loader/Loader";
import { getErrorMessage } from "src/helpers/errors";
import VeraLink from "src/components/VeraLink";
import PaymentStatus from "./PaymentStatus";
import SurveyStats from "src/components/SurveyStats/SurveyStats";
import { getDisplayStatus } from "src/helpers/surveyHelper";
import { SurveyDisplayStatuses } from "src/constants/survey";
import ResearcherEmptyDashboard from "src/components/ResearcherEmptyDashboard/ResearcherEmptyDashboard";

const tableHeaders = [
  {
    id: "externalId",
    label: "ID",
    visibleFor: [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER],
  },
  {
    id: "title",
    label: "Survey Name",
    visibleFor: [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER],
  },
  {
    id: "_id",
    label: "Created",
    visibleFor: [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER],
  },
  {
    id: "end",
    label: "Ending",
    visibleFor: [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER],
  },
  {
    id: "source",
    label: "Source",
    visibleFor: [UserRoles.SUPER_ADMIN],
  },
  {
    id: "internalParamsConfigured",
    label: "Internal Parameters",
    visibleFor: [UserRoles.SUPER_ADMIN],
  },
  {
    id: "active",
    label: "Status",
    visibleFor: [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER],
  },
  {
    id: "createdBy",
    label: "Creator",
    visibleFor: [UserRoles.SUPER_ADMIN],
    visibleIf: ({ createdByRole }: { createdByRole: UserRoles | null }) => createdByRole === UserRoles.RESEARCHER,
  },
  {
    id: "state",
    label: "Paid",
    visibleFor: [UserRoles.RESEARCHER, UserRoles.SUPER_ADMIN],
    visibleIf: ({ createdByRole }: { createdByRole: UserRoles | null }) => createdByRole === UserRoles.RESEARCHER,
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

export const reloadSurveysAtom = atom<boolean>(false);

export enum SurveyIncludesEnum {
  stats = "stats",
  respondents = "respondents",
  createdByEmail = "createdByEmail",
}

type GetSurveyQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: number;
  active?: boolean;
  source?: SurveySource;
  categories?: string[];
  title?: string;
  createdBy?: string;
  createdByRoles?: string;
  includes?: SurveyIncludesEnum;
};

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const isResearcher = user?.role === UserRoles.RESEARCHER;
  const isResearcherSurveyViewByAdmin = !isResearcher && !!searchParams.get(SearchParams.createdBy);

  if (isResearcherSurveyViewByAdmin) {
    tableHeaders.find((cell) => cell.id === "state")!.visibleFor = [UserRoles.SUPER_ADMIN, UserRoles.RESEARCHER];
  }

  const headingPrefix = isResearcher ? "Existing" : "Available";

  const [totalCount, setTotalCount] = useState(0);

  const [anchorEditMenu, setAnchorEditMenu] = useState<null | HTMLElement>(null);

  const [pinQuestionDialog, setPinQuestionDialog] = useState<{ isPinned: boolean; surveyId: string } | false>(false);
  const [pinSubmitDialog, setPinSubmitDialog] = useState<{ isPinned: boolean } | false>(false);
  const navigate = useNavigate();
  const isOpenEditMenu = Boolean(anchorEditMenu);

  const [reloadSurveys, setReloadSurveys] = useAtom(reloadSurveysAtom);

  const [statusFilter] = useAtom(statusFilterAtom);
  const [sourceFilter] = useAtom(sourceFilterAtom);
  const [createdByRoleFilter] = useAtom(createdByRoleAtom);
  const [selectedCategories] = useAtom(selectedCategoriesAtom);
  const [searchValue] = useAtom(searchValueAtom);

  const { enqueueSnackbar } = useSnackbar();

  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(
    ROWS_PER_PAGE_OPTIONS[0]
  );
  const { orderBy, order, handleRequestSort } = useSortableData(setPage);

  const onSyncSurveys = async (source: SurveysSyncSource) => {
    setIsLoading(true);
    await SurveysService.syncSurveys(source);
    getSurveys();
  };

  const retryPayment = async (id: string) => {
    try {
      const { data } = await SurveysService.getPaymentLink(id);
      navigate(AppPaths.researcher.surveys.edit(id, { [SearchParams.clientSecret]: data.paymentSessionId }));
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err as Error), { variant: "error" });
    }
  };

  const getSurveys = () => {
    setIsLoading(true);

    const params: GetSurveyQueryParams = {
      page,
      limit: rowsPerPage,
      sort: orderBy,
      order: order === "asc" ? 1 : -1,
    };
    const createdBy = searchParams.get(SearchParams.createdBy);
    if (createdBy) {
      params.createdBy = createdBy;
    }

    if (statusFilter) params.active = statusFilter === StatusFilterEnum.ACTIVE;
    if (sourceFilter) params.source = sourceFilter;
    if (createdByRoleFilter) {
      if (createdByRoleFilter === UserRoles.RESEARCHER) {
        params.includes = SurveyIncludesEnum.createdByEmail;
      }
      params.createdByRoles = createdByRoleFilter;
    }
    if (selectedCategories.length) params.categories = selectedCategories;
    if (searchValue) params.title = searchValue;

    SurveysService.getSurveys(params).then((res) => {
      setSurveys(res.data.docs);
      setTotalCount(res.data.total);
      setIsLoading(false);
    });
  };

  const handleClickEditMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEditMenu(event.currentTarget);
  };

  const handleCloseEditMenu = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEditMenu(null);
  };

  const openPinDialog = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, surveyId: string, isPinned: boolean) => {
    event.stopPropagation();
    setAnchorEditMenu(null);
    setPinQuestionDialog({ isPinned, surveyId });
  };

  const downloadRedirectParams = async (surveyId: string) => {
    if (!surveyId) return;

    try {
      const data = await SurveysService.downloadRedirectParams(surveyId);
      const blob = new Blob([data.data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `user-redirect-params-for-survey-${surveyId}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      enqueueSnackbar("Failed to download available users", { variant: "error" });
    }
  };

  const downloadReport = async (surveyId: string) => {
    if (!surveyId) return;

    try {
      const res = await SurveysService.getReportUrl(surveyId);
      window.open(res.data.url, "_blank");
    } catch (error) {
      enqueueSnackbar(`Failed to download the report, ${getErrorMessage(error as Error)}`, { variant: "error" });
    }
  };

  const handlePinSurvey = async (doAction: boolean) => {
    setAnchorEditMenu(null);

    if (!doAction) {
      return setPinQuestionDialog(false);
    }

    const { isPinned, surveyId } = pinQuestionDialog as { isPinned: boolean; surveyId: string };

    if (isPinned) {
      await SurveysService.unpinSurvey(surveyId);
    } else {
      await SurveysService.pinSurvey(surveyId);
    }

    setPinQuestionDialog(false);
    setPinSubmitDialog({ isPinned });
    setReloadSurveys(!reloadSurveys);
  };

  useEffect(() => {
    getSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy, reloadSurveys, searchParams, rowsPerPage]);

  return (
    <div className="content-wrapper">
      {surveys.length === 0 && !isLoading && statusFilter === "" && sourceFilter === "" ? (
        <ResearcherEmptyDashboard />
      ) : (
        <>
          {!isResearcher ? <SurveysSyncProgress onSyncSurveys={onSyncSurveys} /> : null}
          {isResearcherSurveyViewByAdmin ? (
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <VeraLink to={AppPaths.admin.researchers.list}>Back</VeraLink>
              </Typography>
            </Box>
          ) : null}
          {surveys.length > 0 || statusFilter !== "" || sourceFilter !== "" ? (
            <>
              <div className="header">
              <div className="text-wrapper">
                {isResearcher && (
                  <MainButton
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate(`/${getPathBase(user?.role)}/surveys/new`)}
                    sx={{ width: "100%", marginBottom: "1rem" }}
                  >
                    Create New Survey
                  </MainButton>
                )}
                <p className="title">{searchParams.get(SearchParams.creatorName) ?? headingPrefix} Surveys</p>
                <p className="subtitle">Showing {surveys.length} surveys</p>
              </div>

              <Stack className="buttons-wrapper" direction="row" spacing={2}>
                {!isResearcher && !isResearcherSurveyViewByAdmin ? (
                  <>
                    <VeraButton
                      variant="outlined"
                      onClick={() => onSyncSurveys(SurveysSyncSource.QUALTRICS)}
                      disabled={isLoading}
                    >
                      Get Qualtrics
                    </VeraButton>
                    <VeraButton
                      variant="outlined"
                      onClick={() => onSyncSurveys(SurveysSyncSource.TYPEFORM)}
                      disabled={isLoading}
                    >
                      Get Typeform
                    </VeraButton>
                    <MainButton
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(`/${getPathBase(user?.role)}/surveys/new`)}
                      sx={{ width: "100%" }}
                    >
                      Create
                    </MainButton>
                  </>
                ) : null}
              </Stack>
            </div>
            <SurveysFilter setPage={setPage} />
            </>
          ) : null}
          {isLoading ? (
            <Loader />
          ) : (
            <Table>
              <TableHeadSortable
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={tableHeaders.filter(
                  (cell) =>
                    cell.visibleFor.includes(user?.role as UserRoles) &&
                    (cell.visibleIf ? cell.visibleIf({ createdByRole: createdByRoleFilter }) : true)
                )}
              />
              <TableBody style={{ borderRadius: "16px" }}>
                {surveys.map((survey: SurveyType) => (
                  <TableRow key={survey._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell className={`${survey.isPinned ? "pinned" : ""}`}>
                      {survey.isPinned && <img src={pinIcon} className="pin-icon" alt="pin icon"></img>}
                      {survey.externalId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <p>
                        <b>{isResearcher ? survey.payload.name : survey.title}</b>
                      </p>
                      {!isResearcher && survey.payload?.name && survey.title !== survey.payload.name && (
                        <p>{survey.payload.name}</p>
                      )}
                    </TableCell>
                    <TableCell>{convertDatetimeStringToReadable(new Date(survey.createdAt).toISOString())}</TableCell>
                    <TableCell>
                      {survey.end ? convertDatetimeStringToReadable(new Date(survey.end).toISOString()) : "--"}
                    </TableCell>
                    {user?.role === UserRoles.SUPER_ADMIN && (
                      <>
                        <TableCell sx={{ textTransform: "capitalize" }}>{survey.source}</TableCell>
                        <TableCell>{survey.internalParamsConfigured ? "Yes" : "No"}</TableCell>
                      </>
                    )}
                    <TableCell
                      className={`status-cell ${
                        getDisplayStatus(survey) === SurveyDisplayStatuses.PendingWeights
                          ? "pending"
                          : survey.active
                          ? "active"
                          : "inactive"
                      }`}
                    >
                      {getDisplayStatus(survey)}
                    </TableCell>
                    {createdByRoleFilter === UserRoles.RESEARCHER ? <TableCell>{survey.createdByEmail}</TableCell> : null}
                    <PaymentStatus retryPayment={retryPayment} isResearcher={isResearcher} survey={survey} />
                    <TableCell className="actions-button">
                      <Button
                        id="basic-button"
                        aria-controls={isOpenEditMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isOpenEditMenu ? "true" : undefined}
                        onClick={handleClickEditMenu}
                        itemID={survey._id}
                      >
                        <img src={ellipsisIcon} alt="menu" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEditMenu}
                        open={anchorEditMenu?.getAttribute("itemID") === survey._id}
                        onClose={handleCloseEditMenu}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => navigate(`/${getPathBase(user?.role)}/surveys/${survey._id}`)}
                          className="menu-list"
                        >
                          <ListItemIcon sx={{ minWidth: "auto" }}>
                            <BorderColorIcon />
                          </ListItemIcon>
                          Edit
                        </MenuItem>

                        <MenuItem onClick={() => setSelectedSurveyId(survey._id)} className="menu-list">
                          <ListItemIcon sx={{ minWidth: "auto" }}>
                            <BarChartIcon />
                          </ListItemIcon>
                          Stats
                        </MenuItem>

                        {createdByRoleFilter === UserRoles.RESEARCHER && !isResearcher ? (
                          <MenuItem
                            onClick={() => navigate(`/${getPathBase(user?.role)}/surveys/${survey._id}/researcher-view`)}
                            className="menu-list"
                          >
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <VisibilityIcon />
                            </ListItemIcon>
                            View Researcher Survey
                          </MenuItem>
                        ) : null}

                        {isResearcher ? null : (
                          <MenuItem onClick={(e) => openPinDialog(e, survey._id, !!survey.isPinned)}>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <img src={pinIcon} className="pin-icon-menu" alt="pin icon" />
                            </ListItemIcon>
                            {survey.isPinned ? "Unpin" : "Pin"}
                          </MenuItem>
                        )}

                        {survey?.source === SurveySource.EXTERNAL && !isResearcher ? (
                          <MenuItem onClick={() => navigate(`/admin/surveys/new?originalSurveyId=${survey._id}`)}>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <img src={cloneIcon} className="pin-icon-menu" alt="pin icon" />
                            </ListItemIcon>
                            Clone
                          </MenuItem>
                        ) : null}

                        {survey?.source === SurveySource.EXTERNAL && !isResearcher ? (
                          <MenuItem onClick={(e) => downloadRedirectParams(survey._id)}>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <DownloadIcon />
                            </ListItemIcon>
                            Download Redirect Params
                          </MenuItem>
                        ) : null}

                        <MenuItem disabled={!survey?.responsesWeightReport} onClick={() => downloadReport(survey._id)}>
                          <ListItemIcon sx={{ minWidth: "auto" }}>
                            <DownloadIcon />
                          </ListItemIcon>
                          Download Weights &amp; Demographics
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <QuestionDialog
            onClose={() => setPinQuestionDialog(false)}
            onSubmit={handlePinSurvey}
            open={!!pinQuestionDialog}
            noBtnStyle={{
              backgroundColor: "white",
              color: "#5F6F74",
            }}
            text={`Are you sure you want to ${
              (pinQuestionDialog as { isPinned: boolean; surveyId: string }).isPinned ? "unpin" : "pin"
            } this survey?`}
            icon={true}
            iconImg={pinIcon}
            iconClass="pin-icon-menu"
          />
          <Dialog open={!!pinSubmitDialog}>
            <div className="submit-pin">
              <img src={successIcon} alt="success" className="icon" />
              <div className="dialog-title">Success!</div>
              <div className="dialog-text">{`Survey successfully ${
                (pinSubmitDialog as { isPinned: boolean }).isPinned ? "un" : ""
              }pinned!`}</div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setPinSubmitDialog(false)}
                className="dialog-button"
              >
                Close
              </Button>
            </div>
          </Dialog>
          <Dialog sx={{ minWidth: 500 }} open={!!selectedSurveyId} onClose={() => setSelectedSurveyId(undefined)}>
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setSelectedSurveyId(undefined)}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 16,
                  top: 3,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <SurveyStats id={selectedSurveyId ?? ""} />
          </Dialog>{" "}
        </>
      )}
      
    </div>
  );
};

export default SurveyList;
