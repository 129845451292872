import { useState } from "react";
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomDateTimePicker from "src/components/DateTimePicker/DateTimePicker";
import { EmailTargetDisplay } from "./EmailTargetDisplay";
import { NotificationSchedule } from "src/types/Survey";
import { PickersTimezone } from "@mui/x-date-pickers";

type PropTypes = {
  notificationSchedule: NotificationSchedule;
  onDelete?: (notificationSchedule: NotificationSchedule) => void;
  onEdit?: (notificationSchedule: NotificationSchedule) => void;
  timezone: PickersTimezone;
  targetingTags?: {
    _id: string;
    tag: string;
  }[];
};

const ScheduledNotification = ({ notificationSchedule, onEdit, onDelete, timezone, targetingTags }: PropTypes) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete && onDelete(notificationSchedule);
    setOpen(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ width: "20%", minWidth: 150 }}>
          <Typography variant="body2" noWrap>
            {notificationSchedule.scheduleOptions.emailTemplate.name}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            width: "35%",
            maxWidth: "35%",
            "& .MuiBox-root": {
              maxWidth: "100%",
              overflow: "hidden",
            },
          }}
        >
          <EmailTargetDisplay payload={notificationSchedule.scheduleOptions} />
        </TableCell>
        <TableCell sx={{ width: "30%", minWidth: 250 }}>
          <CustomDateTimePicker
            disabled
            currentTimezone={timezone}
            value={notificationSchedule.scheduledAt ? new Date(notificationSchedule.scheduledAt) : null}
            setValue={() => {}}
          />
        </TableCell>
        <TableCell sx={{ width: "15%", minWidth: 100 }} align="right">
          {typeof onEdit === "function" && (
            <Tooltip title="Modify notification">
              <IconButton color="primary" size="small" onClick={() => onEdit(notificationSchedule)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {typeof onDelete === "function" && (
            <Tooltip title="Delete notification">
              <IconButton color="error" size="small" onClick={handleDeleteClick}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>

      <Dialog maxWidth="xs" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Scheduled Notification</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduledNotification;
