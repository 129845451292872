import { Dialog, TextField, MenuItem, Box } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React, { useCallback, useEffect, useState } from "react";
import EmailService from "src/services/api/emailService";
import "./EmailTemplateSelector.scss";
import { EmailTemplate } from "src/types/Email";
import { getSendgridTemplateParameters } from "src/helpers/generic";
import { SENDGRID_TEMPLATE_ID } from "src/config/config";
import _ from "lodash";

interface Props {
  expectedParams: string[];
  isReady?: (val: boolean) => void;
  onTemplateSelected?: (template?: EmailTemplate) => void;
  selectedTemplateId?: string;
}

const EmailTemplateSelector = ({ expectedParams, isReady, onTemplateSelected, selectedTemplateId }: Props) => {
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string>();
  const [selectedTemplateHtml, setSelectedTemplateHtml] = useState<string>();
  const [selectedTemplateSubject, setSelectedTemplateSubject] = useState<string>();

  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [emailTemplateWarning, setEmailTemplateWarning] = useState<string>("");
  const [selectedTemplateParameters, setSelectedTemplateParameters] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const setStateValid = (val: boolean) => {
    if (typeof isReady == "function") {
      isReady(val);
    }
  };

  const getSelectedTemplate = () => emailTemplates.find((data) => data.id === selectedEmailTemplate);

  useEffect(() => {
    EmailService.getTemplates().then((res) => {
      if (!res.data.length) return;

      setEmailTemplates(res.data);
      const template2Compare = selectedTemplateId || SENDGRID_TEMPLATE_ID;
      const t = res.data.find((template: EmailTemplate) => template.id === template2Compare)?.id || "";
      setSelectedEmailTemplate(t);
      if (!t?.length) {
        setStateValid(false);
      }
    });
  }, []);

  const fetchTemplate = useCallback(async () => {
    if (!selectedEmailTemplate) return;
    setStateValid(false);
    setEmailTemplateWarning("");

    try {
      const template = await EmailService.getTemplate(selectedEmailTemplate!);

      if (!template.data.html) {
        setEmailTemplateWarning("No html found for the selected template");
        return;
      }

      setSelectedTemplateHtml(template.data.html);
      setSelectedTemplateSubject(template.data.subject);

      const templateParams = getSendgridTemplateParameters(template.data.html!);
      const templateParamsInvalid = !_.isEqual(templateParams, _.intersection(templateParams, expectedParams));
      setSelectedTemplateParameters(templateParams);
      setEmailTemplateWarning(
        templateParamsInvalid
          ? "Template parameter mismatch. Please select another template. For details, check the preview."
          : ""
      );
      if (!templateParamsInvalid) {
        setStateValid(true);
      }
    } catch (error) {
      setEmailTemplateWarning((error as Error)?.message || "failed to fetch selected template");
    }
  }, [selectedEmailTemplate, expectedParams]);

  useEffect(() => {
    if (selectedEmailTemplate) {
      fetchTemplate();
    }
  }, [selectedEmailTemplate, fetchTemplate]);

  useEffect(() => {
    if (emailTemplateWarning?.length) {
      setStateValid(false);
    } else {
      setStateValid(true);
    }
  }, [emailTemplateWarning]);

  useEffect(() => {
    if (typeof onTemplateSelected === "function") {
      onTemplateSelected(getSelectedTemplate());
    }
  }, [selectedEmailTemplate, onTemplateSelected]);

  return (
    <>
      <p>Choose Email Template</p>
      {selectedEmailTemplate !== undefined && (
        <div className="email-template-select" data-cy="email-template-select">
          <TextField
            value={selectedEmailTemplate}
            select
            key={selectedEmailTemplate}
            size="small"
            onChange={(event) => setSelectedEmailTemplate(event.target.value)}
            className="status-input"
            sx={{ minWidth: "100%" }}
          >
            {emailTemplates.map((template) => (
              <MenuItem
                key={template.id}
                value={template.id}
                data-cy={`select-option-${template.name.toLowerCase().replaceAll(" ", "-")}`}
              >
                {template.name}
              </MenuItem>
            ))}
          </TextField>
          {selectedTemplateHtml && (
            <div className="email-template-select__icon" onClick={() => setDialogOpen(true)}>
              <RemoveRedEyeIcon />
            </div>
          )}
        </div>
      )}

      {emailTemplateWarning && <p className="email-template-warning">{emailTemplateWarning}</p>}

      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen} maxWidth="lg">
        {selectedTemplateHtml ? (
          <div className="email-template-preview">
            <div className="email-template-preview__header">Subject: {selectedTemplateSubject || "No Subject"}</div>
            <div className="email-template-preview__content">
              <div dangerouslySetInnerHTML={{ __html: selectedTemplateHtml }}></div>
            </div>
            {emailTemplateWarning && (
              <div className="email-template-params">
                <div className="email-template-params__section">
                  <h4>Supported Parameters:</h4>
                  {expectedParams.map((param) => (
                    <p key={param}>{param}</p>
                  ))}
                </div>
                <div className="email-template-params__section email-template-params__section--unsupported">
                  <h4>Selected Template Parameters:</h4>
                  {selectedTemplateParameters.map((param) => (
                    <p key={param}>{param}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="dialog__loading">
            <p>Loading...</p>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default EmailTemplateSelector;
