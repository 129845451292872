import React, { MouseEventHandler, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/authContext";

import { Button } from "@material-ui/core";
import { Alert } from "@mui/material";
import ReactCodeInput from "react-code-input";
import { useNavigate } from "react-router-dom";
import SignupLayout from "src/components/Signup/SignupLayout";
import { StorageKeys } from "src/constants/storage";
import { getErrorMessage } from "src/helpers/errors";
import { alertStyle, btnStyle, forgotPasswordStyle, pStyle, rowStyle } from "src/styles/auth.styles";
import { UserRoles } from "src/types/User";
import { useValidCode } from "../../hooks/useValidCode";
import { getItemIDB, removeItemIDB } from "../../services/indexedDb";

const VerifyAccountPhone: React.FC<any> = () => {
  /* States and context declaration */
  const { code, setCode, codeIsValid } = useValidCode("");
  const [codeSent, setCodeSent] = useState(false);
  const [, setError] = useState("");
  const authContext = useContext(AuthContext);
  const { signUpUserData } = authContext;
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passwordAdvance, setPasswordAdvance] = useState("");
  const navigation = useNavigate();

  /* Generic Error Message */
  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (signUpUserData == null) {
    return null;
  }

  const getStorageData = async () => {
    const isOTPScreenOn = await getItemIDB(StorageKeys.isOTPScreenOn);
    if (isOTPScreenOn === "1") {
      const localSignUpUserData = JSON.parse(String(await getItemIDB(StorageKeys.signUpUserData)));
      setEmail(localSignUpUserData.email);
      setPhone(localSignUpUserData.phone);
      setPasswordAdvance(localSignUpUserData.passwordAdvance);
    } else {
      setEmail(signUpUserData.email || "");
      setPhone(signUpUserData.phone || "your phone");
      setPasswordAdvance(signUpUserData.passwordAdvance || "");
    }
  };

  const onReSendCodeCallback = (isReSended: boolean) => {
    if (!isReSended) {
      setGenericErrorMessage("Ooops something was wrong please login again");
      setTimeout(function () {
        // TODO: TRS - Put this back when we want phone verification re-enabled
        // navigation(`/verify-phone/${firstName}/${lastName}/${email}/${phone}`);
        navigation(`/login`);
      }, 1000);
    }
  };

  const onReSendCode = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setCodeSent(true);

    if (authContext.mfaRequired) {
      try {
        const codeSended = await authContext.reSendMfaCode(onReSendCodeCallback);
        console.log(codeSended);
      } catch (error: any) {
        setGenericErrorMessage(error.message);
      }
    } else {
      try {
        await authContext.reSendCode(email);
      } catch (error: any) {
        setGenericErrorMessage(error.message);
      }
    }
    setTimeout(() => {
      setCodeSent(false);
    }, 10000);
  };

  const sendCode: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    if (code == null) {
      return;
    }
    if (code.length === 0 || code.length > 6) {
      setError("Invalid code");
      return;
    }
    try {
      if (authContext.mfaRequired) {
        await authContext.sendMfaCode?.(email, code);
      } else {
        await authContext.sendCode?.(email, code);
      }
      await authContext.signInWithEmailnoRedirect(email, passwordAdvance, false);
      // const processPhone = phone.replace("|", "").replace(/\s/g, "");
      // TODO: TRS - What does this function do? It doesn't work at all. It errors out every time it's called.
      // await authContext.onChangePhoneSubmitted(processPhone, email);
      await removeItemIDB(StorageKeys.signUpUserData);
      await removeItemIDB(StorageKeys.isOTPScreenOn);
      if (signUpUserData?.role === UserRoles.RESEARCHER) {
        if (authContext?.refreshUser && authContext?.signInWithEmail) {
          await authContext.signInWithEmail(email, passwordAdvance, () => {});
          await authContext.refreshUser(true);
        }
        navigation("/");
      } else {
        navigation("/register/select-topics");
      }
    } catch (error: any) {
      setGenericErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <SignupLayout title="Please confirm your phone" progress={80}>
      <p style={{ ...pStyle, fontWeight: "400" }}>Please enter the 6 digit code sent to</p>
      <p
        style={{
          ...pStyle,
          marginBottom: "40px",
          marginTop: "0px",
          fontWeight: "700",
        }}
      >
        {phone}
      </p>
      {genericErrorMessage === "" ? null : (
        <Alert style={alertStyle} variant="outlined" severity="error">
          Error: {genericErrorMessage === "" ? "The 6-digit code entered is incorrect." : genericErrorMessage}.
        </Alert>
      )}
      <div style={{ marginBottom: 32 }}>
        <ReactCodeInput
          inputStyle={{
            fontFamily: "monospace",
            borderRadius: "6px",
            border: "1px solid lightgrey",
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
            margin: "4px",
            padding: "0px 0px 0px 8px",
            width: "36px",
            height: "42px",
            fontSize: "32px",
            boxSizing: "border-box",
            color: "black",
            backgroundColor: "white",
          }}
          type="text"
          fields={6}
          name="anyName"
          inputMode="tel"
          onChange={(e: string) => setCode(e)}
          // style={{ padding: '8px'}}
        />
      </div>
      <br />
      <Button
        style={{
          cursor: "pointer",
          ...forgotPasswordStyle,
          color: codeSent ? "#CCC" : "#2EBDEE",
        }}
        onClick={onReSendCode}
        disabled={codeSent}
      >
        Send Code Again
      </Button>
      <div style={{ ...rowStyle, marginTop: "60px" }}>
        <div style={{ flex: "1 1 60%" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnStyle}
            fullWidth
            onClick={(e) => {
              sendCode(e);
            }}
            disabled={codeIsValid !== ""}
          >
            Next
          </Button>
        </div>
        <div style={{ flex: "1 1 30%" }}></div>
      </div>
    </SignupLayout>
  );
};

export default VerifyAccountPhone;
