import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { NotificationsTagTypeEnum, SurveyNotificationLog } from "../../../../types/Survey";
import { getNotificationTargetingTags } from "src/helpers/surveyHelper";
import { UTM_TARGETING_TAG_PREFIX } from "src/types/TargetingTag";
import { umtAllTagkey } from "src/constants/survey";
import { allAvailableUtmTagsAtom } from "src/atoms/utm-tags";
import { availableTargetingTagsAtom } from "src/atoms/targeting-tags";

import { useAtom } from "jotai";

export const EmailTargetDisplay: React.FC<{
  payload: SurveyNotificationLog["payload"];
}> = ({ payload }) => {
  const [allAvailableUtmTags] = useAtom(allAvailableUtmTagsAtom);
  const [targetingTags] = useAtom(availableTargetingTagsAtom);

  const tagId2Name = [...(targetingTags ?? []), ...(allAvailableUtmTags ?? [])].reduce<Record<string, string>>(
    (res, tag) => {
      res[tag._id] = tag.tag;
      return res;
    },
    {}
  );

  const renderEmailTarget = () => {
    if (payload.singleEmailToNotify) {
      return (
        <Typography variant="body2" component="span">
          {payload.singleEmailToNotify}
        </Typography>
      );
    }

    if (payload.notificationsTagType === NotificationsTagTypeEnum.TAG) {
      const tags = getNotificationTargetingTags(payload).map((tag) => {
        return tagId2Name[tag] ? tagId2Name[tag] : tag === umtAllTagkey ? `${UTM_TARGETING_TAG_PREFIX}${tag}` : tag;
      });

      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {tags.map((tag, index) => (
            <Chip key={tag + index} label={tag} size="small" />
          ))}
        </Box>
      );
    }

    return (
      <Typography variant="body2" component="span">
        {payload.notificationsTagType}
      </Typography>
    );
  };

  return renderEmailTarget();
};
