import { Grid, LinearProgress, Paper } from "@mui/material";
import { ReactNode } from "react";
import verasightLogo from "src/assets/landing-page/verasight-logo-community.svg";
import SideCarousel from "src/components/SignUpSideCarousel/SignUpSideCarousel";
import {
  backgroundStyle,
  containerStyle,
  h1Style,
  logoStyle,
  notice as noticeStyle,
  paperStyle,
} from "src/styles/auth.styles";

interface Props {
  title: string;
  children: ReactNode;
  progress: number;
  notice?: string;
  Carousel?: ReactNode;
}
export default function SignupLayout({ children, title, progress, notice, Carousel }: Readonly<Props>) {
  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <Paper elevation={1} style={paperStyle}>
          <Grid style={{ ...logoStyle, justifyContent: "start" }}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          {notice ? <div style={noticeStyle}>{notice}</div> : null}
          <LinearProgress variant="determinate" value={progress} style={{ color: "#2EBDEE" }} />
          <h1 style={h1Style}>{title}</h1>

          {children}
        </Paper>
      </div>

      {Carousel ?? <SideCarousel />}
    </div>
  );
}
