import React, { useState, useEffect, useContext, useCallback } from "react";
import { IUserAuth } from "../interfaces/IUserAuth";
import { IAuthAWS } from "../interfaces/IAuthAWS";
import { IAuthFunctionalities, SocialMedia } from "../interfaces/IAuthFunctionalities";

import * as cognito from "../libs/cognito";
import { initGlobalConfig } from "../core/theme";
import { IMessage } from "../interfaces/IMessage";
import sendMessage from "../core/flash-message";
import { IAuth, AuthStatus, SignUpUserData, RedeemUserData } from "../interfaces/IAuth";
import { User } from "src/types/User";
import UsersService from "src/services/api/usersService";
import { completeLogin } from "../helpers/dataLayers";
import { clearIDB, getItemIDB, removeItemIDB, setItemIDB } from "../services/indexedDb";
import { dateFromId } from "../helpers/generic";
import { StorageKeys } from "src/constants/storage";

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.LOADING,
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn: React.FunctionComponent = ({ children }: any) => {
  const { authStatus, user }: IAuth = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SIGNED_IN && user ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent = ({ children }: any) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  return <>{authStatus === AuthStatus.SIGNED_OUT ? children : null}</>;
};

interface IProps {
  config: IAuthAWS;
  authConfig?: IAuthFunctionalities;
}

const AuthProvider: React.FC<IProps> = (props: any) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.LOADING);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [globalConfig, setGlobalConfig] = useState({});
  const [signUpUserData, setSignUpUserData] = useState<SignUpUserData>({});
  const [user, setUser] = useState<Partial<User>>();
  const [redeemUserData, setRedeemUserData] = useState<RedeemUserData>({});

  const refreshUser = useCallback(async (login = false) => {
    return await UsersService.getUserData()
      .then(async (r) => {
        if (login) {
          completeLogin(
            r.data?.firstName,
            r.data?.email,
            r.data?.phone,
            false,
            r.data?._id && dateFromId(r.data?._id),
            r.data?.completedSurveyCount
          );
        }
        setUser(r.data);
      })
      .catch((_) => _);
  }, []);

  const initialTheme = {
    ...props.theme,
  };

  const getSession = async () => {
    return cognito.getSession();
  };

  const getAttributes = async () => {
    return cognito.getAttributes();
  };

  const getSessionInfo = async () => {
    try {
      const session: any = await getSession();
      if (session) {
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken,
        });

        storeData("accessToken", session.accessToken.jwtToken).catch((e) => console.error(e));
        storeData("refreshToken", session.refreshToken.token).catch((e) => console.error(e));
        storeData("idToken", session.idToken.jwtToken).catch((e) => console.error(e));

        await refreshUser(false);
        setAuthStatus(AuthStatus.SIGNED_IN);
      }
    } catch (err) {
      setAuthStatus(AuthStatus.SIGNED_OUT);
    }
  };

  useEffect(() => {
    /*
    **
    Configurable params initialization for cognito
    **
    */

    cognito.initCognito(props.config).catch((error: any) => {
      const message: IMessage = {
        message: error.code,
        description: `Ooops something wrong in the process: ${error.message}`,
        type: "danger",
      };
      sendMessage(message);
    });

    /*
    **
    Default theme and authentication config initialization
    **
    */
    const initialConfig = initGlobalConfig(props.authConfig);
    setGlobalConfig({
      ...globalConfig,
      theme: initialTheme,
      authConfig: initialConfig,
      config: props.config,
    });

    /*
    **
    Cognito session initialization
    **
    */
    getSessionInfo().catch((err) => console.error(err));
    // eslint-disable-next-line
  }, []);

  if (authStatus === AuthStatus.LOADING) {
    return null;
  }

  const signInWithEmail = async (username: string, password: string, callback: () => void) => {
    try {
      const session: any = await cognito.signInWithEmail(username, password, callback, setMfaRequired);
      if (session) {
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken,
        });

        await storeData("accessToken", session.accessToken.jwtToken).catch((e) => console.error(e));
        await storeData("refreshToken", session.refreshToken.token).catch((e) => console.error(e));
        await storeData("idToken", session.idToken.jwtToken).catch((e) => console.error(e));

        const attr: any = await getAttributes();
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SIGNED_IN);
      }
      return session;
    } catch (err) {
      setAuthStatus(AuthStatus.SIGNED_OUT);
      throw err;
    }
  };

  const signInWithEmailnoRedirect = async (
    username: string,
    password: string,
    shouldChangeAuthStatus: boolean = true
  ) => {
    try {
      const userData = (await cognito.signInWithEmailNew(username, password)) as any;
      const session = userData.signInUserSession;
      // const session: any = await cognito.signInWithEmail(username, password, callback, setMfaRequired);
      if (session) {
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken,
        });

        storeData("accessToken", session.accessToken.jwtToken).catch((e) => console.error(e));
        storeData("refreshToken", session.refreshToken.token).catch((e) => console.error(e));
        storeData("idToken", session.idToken.jwtToken).catch((e) => console.error(e));
        const attr: any = await getAttributes();
        setAttrInfo(attr);

        if (shouldChangeAuthStatus) {
          setAuthStatus(AuthStatus.SIGNED_IN);
        }
      }
      return session;
    } catch (err) {
      setAuthStatus(AuthStatus.SIGNED_OUT);
      throw err;
    }
  };

  const federatedSignIn = async (configAWS: IAuthAWS, provider: SocialMedia) => {
    try {
      return cognito.federatedSignIn(configAWS, provider);
    } catch (err) {
      setAuthStatus(AuthStatus.SIGNED_OUT);
      throw err;
    }
  };

  const signUpWithEmail = async (
    username: string,
    email: string,
    password: string,
    phone_number: string,
    name: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      return await cognito.signUpUserWithEmail(username, email, password, phone_number, name, firstName, lastName);
    } catch (err) {
      throw err;
    }
  };

  const signUpWithPhone = async ({ username, email, password, phone_number, name, firstName, lastName }: IUserAuth) => {
    try {
      return await cognito.signUpUserWithPhone(username, email, password, phone_number, name, firstName, lastName);
    } catch (err) {
      throw err;
    }
  };

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SIGNED_OUT);
  }

  const verifyCode = async (username: string, code: string) => {
    try {
      return await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  };

  const setAttribute = async (attr: any) => {
    try {
      return await cognito.setAttribute(attr);
    } catch (err) {
      throw err;
    }
  };

  const setAttributes = async (attr: IUserAuth) => {
    try {
      return await cognito.setAttributes(attr);
    } catch (err) {
      throw err;
    }
  };

  const sendCodeRecoverPassword = async (username: string) => {
    try {
      return await cognito.sendCodeRecoverPassword(username);
    } catch (err) {
      throw err;
    }
  };

  const sendCode = async (username: string, code: string) => {
    try {
      return await cognito.sendCode(username, code);
    } catch (err) {
      throw err;
    }
  };

  const sendMfaCode = async (username: string, code: string) => {
    try {
      await cognito.sendMfaCode(username, code);
      setMfaRequired(false);
      return true;
    } catch (err) {
      throw err;
    }
  };

  const reSendCode = async (username: string) => {
    try {
      return await cognito.reSendCode(username);
    } catch (err) {
      throw err;
    }
  };

  const reSendMfaCode = async (callback: (sended: boolean) => void) => {
    try {
      return await cognito.reSendMfaCode(callback);
    } catch (err) {
      throw err;
    }
  };

  const enableMFA = async (username: string) => {
    try {
      return await cognito.enableMFA(username);
    } catch (err) {
      throw err;
    }
  };

  const forgotPassword = async (username: string, code: string, password: string) => {
    try {
      return await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  };

  const forgotPasswordToken = async (username: string, password: string) => {
    try {
      return await cognito.forgotPasswordToken(username, password);
    } catch (err) {
      throw err;
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      return await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  };

  const rememberDevice = async () => {
    try {
      return await cognito.rememberDevice();
    } catch (err) {
      throw err;
    }
  };

  const forgotDevice = async () => {
    try {
      return await cognito.forgotDevice();
    } catch (err) {
      throw err;
    }
  };

  const onConfirmCodeResendCodePhone = async (phone: string, email: string) => {
    try {
      return await cognito.onConfirmCodeResendCodePhone(phone, email);
    } catch (err) {
      throw err;
    }
  };

  const onConfirmCodeSubmitted = async (code: string, newEmail: string, email: string) => {
    try {
      return await cognito.onConfirmCodeSubmitted(code, newEmail, email);
    } catch (err) {
      throw err;
    }
  };

  const onConfirmCodeSubmittedPhone = async (code: string, phone: string, email: string) => {
    try {
      return await cognito.onConfirmCodeSubmittedPhone(code, phone, email);
    } catch (err) {
      throw err;
    }
  };

  const onChangePhoneSubmitted = async (phone: string, email: string) => {
    try {
      return await cognito.onChangePhoneSubmitted(phone, email);
    } catch (err) {
      throw err;
    }
  };

  const loadSignupUserDataFromStorageIfSignedUp = async (): Promise<boolean | void> => {
    const signUpUserData = await getItemIDB(StorageKeys.signUpUserData);
    const isOTPScreenOn = await getItemIDB(StorageKeys.isOTPScreenOn);
    if (isOTPScreenOn === "1" && signUpUserData) {
      setSignUpUserData(JSON.parse(signUpUserData));
      return true;
    }
  };

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    mfaRequired,
    globalConfig,
    signUpUserData,
    setSignUpUserData,
    loadSignupUserDataFromStorageIfSignedUp,
    redeemUserData,
    setRedeemUserData,
    user,
    refreshUser,
    signUpWithEmail,
    signInWithEmail,
    signInWithEmailnoRedirect,
    signUpWithPhone,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    setAttributes,
    enableMFA,
    reSendCode,
    sendCodeRecoverPassword,
    sendMfaCode,
    reSendMfaCode,
    federatedSignIn,
    rememberDevice,
    forgotDevice,
    forgotPasswordToken,
    storeData,
    onConfirmCodeResendCodePhone,
    onConfirmCodeSubmitted,
    onConfirmCodeSubmittedPhone,
    onChangePhoneSubmitted,
    setAuthStatus,
  };

  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
};

export const storeData = async (key: string, value: string) => {
  try {
    await setItemIDB(key, value);
  } catch (e) {
    throw e;
  }
};

export const getData = async (key: string) => {
  try {
    const value = await getItemIDB(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    throw e;
  }
};

export const removeData = async (key: string) => {
  try {
    await removeItemIDB(key);
  } catch (e) {
    throw e;
  }
};

export const logoutAction = async () => {
  localStorage.clear();
  await clearIDB();
};

export default AuthProvider;
