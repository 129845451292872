import { Button, Grid, InputAdornment } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Alert, IconButton, OutlinedInput, Snackbar } from "@mui/material";
import { AuthContext } from "../../contexts/authContext";
import { useValidCode } from "../../hooks/useValidCode";
import { useValidPasswordAdvance } from "../../hooks/useValidPasswordAdvance";
import { PasswordType } from "../../interfaces/IPassType";
import {
  btnStyle,
  btnStyleDisabled,
  checkListItemStyle,
  checkListStyle,
  inputStyle,
  passwordRecoverySubtitleStyle,
  passwordSubtitleStyle,
  plabelStyle,
  pleseEnterNewPasswordStyle,
  rowStyle,
} from "../../styles/auth.styles";

import SignupLayout from "src/components/Signup/SignupLayout";
import { StorageKeys } from "src/constants/storage";
import { disableKeyDown } from "src/helpers/event-handlers";
import SnackBarClose from "../../components/common/SnackbarClose";
import { setItemIDB } from "../../services/indexedDb";

type CheckPasswordProps = {
  type: PasswordType;
};

type CustomizedState = {
  text: string;
};

const CheckPassword: React.FC<any> = ({ type }: CheckPasswordProps) => {
  const location = useLocation();
  const locationState = location.state as CustomizedState;

  const { passwordAdvance, setPasswordAdvance, passwordAdvanceIsValid } = useValidPasswordAdvance(null);
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassError, setConfirmPassError] = useState<string>();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isOTPValid, setIsOTPValid] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");

  const { code, setCode, codeIsValid: invalidCodeErrMessage } = useValidCode(null);

  const authContext = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  /* Generic Error Message */
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirm: false,
  });

  const handleClickShowPassword = (name: "password" | "confirm") => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  /*
  Functions Section
  */
  useEffect(() => {
    onComparePassword(confirmPass);
    // eslint-disable-next-line
  }, [confirmPass, passwordAdvance]);

  useEffect(() => {
    let isValid = isPasswordValid && !confirmPassError?.length && confirmPass?.length > 0;
    if (type === PasswordType.RECOVERY) {
      isValid = isValid && !invalidCodeErrMessage?.length && !!code;
    }
    setNextEnabled(isValid);
  }, [isPasswordValid, confirmPassError, confirmPass, invalidCodeErrMessage, type]);

  useEffect(() => {
    if (passwordAdvanceIsValid[0].text === "Password Strength: Good") {
      setIsPasswordValid(true);
    }
  }, [passwordAdvanceIsValid]);

  useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line
  }, []);

  const getStorageData = async () => {
    if (await authContext.loadSignupUserDataFromStorageIfSignedUp?.()) {
      navigation(`/register/verify-phone`);
    }
  };

  useEffect(() => {
    if (locationState) {
      setAlertMessage(locationState?.text);
    }
  }, [locationState]);

  if (!authContext.signUpUserData) {
    return null;
  }
  const { email } = authContext.signUpUserData;

  const onComparePassword = (confirmPass: string) => {
    if (confirmPass === "" || confirmPass === passwordAdvance) {
      setConfirmPassError(undefined);
      return;
    }
    setConfirmPassError("The passwords you have entered don’t match. Please correct to proceed!");
  };

  const onChangePassword = async (e: any) => {
    e.stopPropagation();
    if (!email && !passwordAdvance && invalidCodeErrMessage) {
      setConfirmPass("");
      setPasswordAdvance("");
      return;
    }
    try {
      await authContext.forgotPassword(email, code, passwordAdvance);
      const successMessage = "Your password has been successfully reset! You can now sign in with your new password";
      navigation("/login", { state: { successMessage } });
    } catch (error: any) {
      if (error.name && error.name === "CodeMismatchException") {
        setIsOTPValid(true);
      }
      setGenericErrorMessage(error.message);
      setOpen(true);
    }
  };

  const onNextPressed = async (e: any) => {
    setIsPasswordValid(true);
    e.stopPropagation();

    if (passwordAdvance) {
      if (authContext.setSignUpUserData) {
        authContext.setSignUpUserData((prevData) => ({
          ...prevData,
          passwordAdvance,
        }));
      } else {
        setAlertMessage("Failed to set password in state");
        return;
      }
      await setItemIDB(
        StorageKeys.signUpUserData,
        JSON.stringify({
          ...authContext.signUpUserData,
          passwordAdvance,
        })
      );
      return navigation("/register/create-account");
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onNextClick = async (e: any) => {
    if (type === PasswordType.RECOVERY) {
      await onChangePassword(e);
    } else {
      await onNextPressed(e);
    }
  };

  return (
    <SignupLayout title={type === PasswordType.RECOVERY ? "Password recovery" : "Set Password"} progress={40}>
      {type === PasswordType.RECOVERY ? (
        <>
          <p style={passwordRecoverySubtitleStyle}>Please enter your verification code</p>

          <OutlinedInput
            inputProps={{ autoComplete: "new-password" }}
            type="password"
            disabled={true}
            style={{ display: "none" }}
          />
          <OutlinedInput
            size="small"
            placeholder="Verification code"
            id="field1"
            inputProps={{
              autoComplete: "new-password",
            }}
            error={isOTPValid}
            type="number"
            style={{ ...inputStyle, maxWidth: "400px" }}
            onKeyDown={(event) => disableKeyDown(event, ["ArrowDown", "ArrowUp"])}
            onChange={(e: any) => {
              setIsOTPValid(false);
              setCode(e.target.value);
            }}
          />
        </>
      ) : null}

      <p style={pleseEnterNewPasswordStyle}>Please enter a new password</p>
      <p style={passwordSubtitleStyle}>Password</p>
      <OutlinedInput
        size="small"
        placeholder="Password"
        name="password"
        type={showPassword.password ? "text" : "password"}
        style={{ ...inputStyle, maxWidth: "400px" }}
        error={!isPasswordValid}
        onChange={(e: any) => setPasswordAdvance(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword("password")}
              edge="end"
            >
              {showPassword.password ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <p style={plabelStyle}>Confirm Password</p>
      <OutlinedInput
        size="small"
        placeholder="Confirm Password"
        type={showPassword.confirm ? "text" : "password"}
        style={{ ...inputStyle, maxWidth: "400px" }}
        error={!!confirmPassError}
        onChange={(e: any) => setConfirmPass(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword("confirm")}
              edge="end"
            >
              {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Grid style={checkListStyle}>
        {passwordAdvanceIsValid
          ? // eslint-disable-next-line
            passwordAdvanceIsValid.map((item: any, index: number) => {
              if (item.text) {
                if (item.active) {
                  return (
                    <p key={index} style={{ ...checkListItemStyle, color: "#384144" }}>
                      <CheckIcon style={{ width: "20px", color: "#2ebdee" }} />
                      {item.text}
                    </p>
                  );
                } else {
                  return (
                    <p key={index} style={checkListItemStyle}>
                      <CheckIcon style={{ width: "20px" }} />
                      {item.text}
                    </p>
                  );
                }
              }
            })
          : null}

        {!confirmPassError && confirmPass ? (
          <p key={"confirm-pass-error"} style={{ ...checkListItemStyle, color: "#384144" }}>
            <CheckIcon style={{ width: "20px", color: "#2ebdee" }} />
            {"Passwords match"}
          </p>
        ) : null}
        {confirmPassError ? (
          <Alert variant="filled" severity="warning">
            {confirmPassError}
          </Alert>
        ) : null}
      </Grid>
      <div style={{ ...rowStyle, marginTop: "60px" }}>
        <div style={{ flex: "1 1 30%" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              ...btnStyle,
              backgroundColor: "white",
              color: "#5F6F74",
            }}
            fullWidth
            onClick={(e) => {
              navigation(-1);
            }}
          >
            Back
          </Button>
        </div>
        <div style={{ flex: "1 1 60%" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={nextEnabled ? btnStyle : btnStyleDisabled}
            fullWidth
            onClick={onNextClick}
            disabled={!nextEnabled}
          >
            Next
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={genericErrorMessage}
        action={<SnackBarClose handleClose={handleClose} />}
      />

      {alertMessage ? (
        <div style={{ position: "absolute", width: "100%" }}>
          <Alert
            variant="filled"
            severity="success"
            onClose={() => setAlertMessage("")}
            sx={{ maxWidth: "925px", margin: "auto", mt: "25px" }}
          >
            {alertMessage}
          </Alert>
        </div>
      ) : null}
    </SignupLayout>
  );
};

export default CheckPassword;
