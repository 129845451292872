import { MFAType } from "../interfaces/IAuthFunctionalities";

export const AWS_CONFIG = {
  /* User Pool ID */
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  /* Cognito Client ID */
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  /* Cognito Region */
  region: process.env.REACT_APP_AWS_REGION ?? "us-west-2",
  /*
    **
    For OAuth configuration, If you wanna use a 3th
    factor authentication, like facebook, google or any other
    you need to have that configuration in your AWS Cognito account
    **
    */
  oauth: {
    /* Application domain */
    domain:
      process.env.REACT_APP_AWS_APPLICATION_DOMAIN ?? "https://verasight-805975320018.auth.us-west-2.amazoncognito.com",

    /* Redirect url executing sign in */
    redirectSignIn: "topflightapps://authentication/",

    /* Redirect url executing sign out */
    redirectSignOut: "topflightapps://authentication/",

    /*
    **
    'code' for Authorization code grant,
    'token' for Implicit grant
    Note that REFRESH token will only be generated when the responseType is code
    **
    */
    responseType: "code",

    /* Authorized scopes 'phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin' */
    scope: ["phone", "email"],
  },

  /* User Pool ID */
  /* federationTarget: "", */
};

export const AUTH_CONFIG = {
  RememberDevice: false,
  MFA: MFAType.FULL,
};

export const TIKTOK_PIXEL_ID = process.env.REACT_APP_TIKTOK_PIXEL_ID;

export const TWITTER_PIXEL_ID = process.env.REACT_APP_TWITTER_PIXEL_ID;
export const TWITTER_PIXEL_ID_TWO = process.env.REACT_APP_TWITTER_PIXEL_ID_TWO;

export const SENDGRID_TEMPLATE_ID = process.env.REACT_APP_SENDGRID_TEMPLATE_ID;
