import { Box, Checkbox, Divider, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "@mui/lab";
import "./RightColumnFields.scss";
import { useEffect, useState } from "react";
import _ from "lodash";
import { atom, useAtom } from "jotai";
import { TargetingSurveyToUsersEnum } from "../../../../types/Survey";
import TargetingTagInput from "./TargetingTagInput";
import CsvDialog from "./CsvDialog";
import ExcludeTagsInput from "./ExcludeTagsInput";
import styled from "@emotion/styled";
import UtmCampaignSelection from "./UtmCampaignSelection";

export const utmCampaignTargetingTagsAtom = atom<string[]>([]);
export const utmCampaignTargetingTagExcludesAtom = atom<string[]>([]);

const UploadCsvButton = styled.button({
  width: "max-content",
  border: "none",
  backgroundColor: "transparent",
});

const RightColumnFields = ({
  usersFile,
  setUsersFile,
  availableTargetingTags,
  targetingTags,
  setTargetingTags,
  setTargetingSurveyToUsers,
  targetingSurveyToUsers,
  publish,
  AdapterDateFns,
  targetUsersAfterDate,
  setTargetUsersAfterDate,
  csvTag,
  setCsvTag,
  excludeGlobalTags,
  setExcludeGlobalTags,
  excludedTargetingTags,
  setExcludedTargetingTags,
  utmApplyingLogic,
  setUtmApplyingLogic,
  utmTargetUsersAfterSignupDate,
  setUtmTargetUsersAfterSignupDate,
}: any) => {
  const [csvDialogOpen, setCsvDialogOpen] = useState(false);
  const [csvTagInternal, setCsvTagInternal] = useState<string | undefined>(undefined);
  const [csvDialogOkDisabled, setCsvDialogOkDisabled] = useState<boolean>(true);

  const [utmCampaignTargetingTags, setUtmCampaignTargetingTags] = useAtom(utmCampaignTargetingTagsAtom);
  const [utmCampaignTargetingTagExcludes, setUtmCampaignTargetingTagExcludes] = useAtom(
    utmCampaignTargetingTagExcludesAtom
  );

  useEffect(() => {
    setCsvDialogOkDisabled(_.isNil(usersFile) || _.isEmpty(csvTagInternal?.trim()));
  }, [usersFile, csvTagInternal]);

  const openCsvDialog = () => {
    setCsvTagInternal(csvTag);
    setCsvDialogOpen(true);
  };

  const cancelCsvDialog = () => {
    setUsersFile(undefined);
    setCsvTag("");
  };

  const onChangeCsvTag = (_evt: any, newValue: any) => {
    setCsvTag(newValue);
  };

  return (
    <div>
      <Divider sx={{ marginY: "24px" }} />

      <h3 className="target-container__title">Target this survey to an audience</h3>
      <div className="last-container">
        <Box className="input-container-survey multiplier-block" style={{ marginBottom: 0 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              value={targetingSurveyToUsers}
            >
              <Box
                sx={{
                  marginBottom: "1rem",
                  maxWidth: "100%",
                  gap: 20,
                }}
              >
                <div className="target-survey-block">
                  <FormControl fullWidth>
                    <Box sx={{ marginBottom: "1rem", maxWidth: "100%", minWidth: 120 }}>
                      <div className="target-survey-block">
                        <UtmCampaignSelection
                          setUtmCampaignTargetingTagExcludes={setUtmCampaignTargetingTagExcludes}
                          setUtmCampaignTargetingTags={setUtmCampaignTargetingTags}
                          utmCampaignTargetingTagExcludes={utmCampaignTargetingTagExcludes}
                          utmCampaignTargetingTags={utmCampaignTargetingTags}
                          utmApplyingLogic={utmApplyingLogic}
                          setUtmApplyingLogic={setUtmApplyingLogic}
                          utmTargetUsersAfterSignupDate={utmTargetUsersAfterSignupDate}
                          setUtmTargetUsersAfterSignupDate={setUtmTargetUsersAfterSignupDate}
                        />
                      </div>
                    </Box>
                  </FormControl>
                </div>
              </Box>

              <div className="target-survey-block">
                <FormControlLabel
                  onChange={() => setTargetingSurveyToUsers(TargetingSurveyToUsersEnum.ALL)}
                  value={TargetingSurveyToUsersEnum.ALL}
                  control={<Radio color="primary" />}
                  label="All"
                />
                <div className="input-container-survey">
                  <div className="input-label">After signup date</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={targetUsersAfterDate ? new Date(targetUsersAfterDate) : null}
                      onChange={(newValue: any) => {
                        setTargetUsersAfterDate(newValue);
                      }}
                      disabled={targetingSurveyToUsers !== TargetingSurveyToUsersEnum.ALL}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <FormControlLabel
                onChange={() => setTargetingSurveyToUsers(TargetingSurveyToUsersEnum.TAG)}
                value={TargetingSurveyToUsersEnum.TAG}
                control={<Radio color="primary" />}
                label={
                  <Box className="input-container-survey">
                    <div className="target-box__label">Tag based targeting</div>
                  </Box>
                }
              />

              <TargetingTagInput
                targetingTags={targetingTags}
                setTargetingTags={setTargetingTags}
                availableTargetingTags={availableTargetingTags}
                targetingSurveyToUsers={targetingSurveyToUsers}
                csvTag={csvTag}
                onChangeCsvTag={onChangeCsvTag}
              />

              <UploadCsvButton
                onClick={targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG ? openCsvDialog : () => false}
                type="button"
              >
                <div
                  className="upload-users-container"
                  style={{
                    paddingTop: "0.5em",
                    width: "100%",
                    justifyContent: "left",
                  }}
                >
                  <label
                    className="upload-users-label"
                    htmlFor="upload-users"
                    style={
                      targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG
                        ? { cursor: "default", backgroundColor: "#5f6f74", border: "1px solid #5f6f74" }
                        : {}
                    }
                  >
                    <p>Upload CSV</p>
                  </label>
                </div>
              </UploadCsvButton>

              <CsvDialog
                cancelCsvDialog={cancelCsvDialog}
                csvDialogOpen={csvDialogOpen}
                availableTargetingTags={availableTargetingTags}
                csvTagInternal={csvTagInternal}
                usersFile={usersFile}
                setUsersFile={setUsersFile}
                targetingSurveyToUsers={targetingSurveyToUsers}
                csvDialogOkDisabled={csvDialogOkDisabled}
                setCsvTagInternal={setCsvTagInternal}
                setCsvDialogOpen={setCsvDialogOpen}
                onChangeCsvTag={onChangeCsvTag}
              />

              <Box sx={{ marginTop: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => setExcludeGlobalTags(event.target.checked)}
                      checked={excludeGlobalTags}
                    />
                  }
                  label="Exclude those with global tags"
                />

                <ExcludeTagsInput
                  excludeGlobalTags={excludeGlobalTags}
                  allTargetingTags={availableTargetingTags}
                  excludedTargetingTags={excludedTargetingTags}
                  setExcludedTargetingTags={setExcludedTargetingTags}
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>

        <button
          type="button"
          onClick={publish}
          className="last-container__button"
          disabled={
            targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG && _.isEmpty(targetingTags) && _.isEmpty(csvTag)
          }
        >
          Save Information
        </button>
      </div>
    </div>
  );
};
export default RightColumnFields;
