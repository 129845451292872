import { useEffect, useState } from "react";
import { Box, Chip, Stack, Table, TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import { convertDatetimeStringToReadable } from "src/helpers/date";
import useSortableData from "src/hooks/useSortableData";
import TableHeadSortable from "src/components/common/TableHeadSortable";
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import UsersService from "src/services/api/usersService";
import { UserTargetingAction, UserTargetingAuditFilterType } from "src/types/User";
import usePagination from "src/hooks/usePagination";
import Loader from "src/components/Loader/Loader";
import { getErrorMessage } from "src/helpers/errors";
import FilterWrapper from "src/components/common/FilterWrapper";
import DateFilter from "src/components/common/DateFilter";
import { useDateFilter } from "src/hooks/useDatefilter";
import SelectionFilter from "src/components/common/SelectionFilter";
import { dowloadCsv } from "src/helpers/dowload-stream";
import ProgressDialog, { ProgressBarType } from "src/components/Progress/ProgressDialog";
import VeraButton from "src/components/VeraButton";

const tableHeadCells = [
  {
    id: "userId",
    label: "Email",
  },
  {
    id: "tagIds",
    label: "tags",
  },
  {
    id: "_id",
    label: "Created",
  },
  {
    id: "action",
    label: "Action",
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

const UserTargetingAuditList = ({ tagIds }: { tagIds: string[] }) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(
    ROWS_PER_PAGE_OPTIONS[0]
  );
  const { orderBy, order, handleRequestSort } = useSortableData(setPage);
  const [filters, setFilters] = useState<UserTargetingAuditFilterType>();
  const [openFilters, setOpenFilters] = useState(false);
  const { dateAfter, dateBefore, setDateAfter, setDateBefore } = useDateFilter();
  const [actionFilters, setActionFilters] = useState<UserTargetingAction[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadProgressOpen, setDownloadProgressOpen] = useState<boolean>(false);

  const getParams = () => ({
    tagIds,
    page,
    limit: rowsPerPage,
    sort: orderBy,
    order: order === "asc" ? 1 : -1,
    ...filters,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [UsersService.getUserTargetingAudits.name, tagIds, page, rowsPerPage, orderBy, order, filters],
    queryFn: () => UsersService.getUserTargetingAudits(getParams()),
  });

  const applyFilters = () => {
    setPage(1);
    setFilters({
      ...(dateAfter && { createdAtAfterDate: new Date(dateAfter).toISOString() }),
      ...(dateBefore && { createdAtBeforeDate: new Date(dateBefore).toISOString() }),
      ...(actionFilters.length && { actions: actionFilters }),
      tagIds,
    });
    setOpenFilters(false);
  };
  const clearFilters = () => {
    setFilters({ tagIds });
    setDateAfter(null);
    setDateBefore(null);
    setActionFilters([]);
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(getErrorMessage(error as Error), { variant: "error" });
    }
  }, [isError]);

  const onDownloadCSV = async () => {
    setDownloadProgressOpen(true);
    try {
      await dowloadCsv(async () => {
        const res = await UsersService.dowloadUserTargetingAudits(getParams());
        return res.data;
      }, "verasight-targeting-audits.csv");
    } catch (err) {
      enqueueSnackbar(`Error downloading CSV: ${getErrorMessage(err as any)}`, { variant: "error" });
    } finally {
      setDownloadProgressOpen(false);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="header">
        <div className="text-wrapper">
          <p className="title">User Target Audits</p>
          <p className="subtitle">Displays when selected survey tags were added or removed from a user</p>
        </div>
      </div>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <FilterWrapper
          openFilters={openFilters}
          clearFilters={clearFilters}
          applyFilters={applyFilters}
          setOpenFilters={setOpenFilters}
        >
          <DateFilter
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            setDateAfter={setDateAfter}
            setDateBefore={setDateBefore}
            label="Action Performed At"
          />
          <SelectionFilter<UserTargetingAction>
            items={Object.values(UserTargetingAction)}
            selectedItems={actionFilters}
            setSelectedItems={setActionFilters}
            label="Action"
          />
        </FilterWrapper>
        <VeraButton
          variant="outlined"
          onClick={() => onDownloadCSV()}
          disabled={isLoading || !data?.data.total}
          sx={{
            fontWeight: "bold",
          }}
        >
          Download CSV
        </VeraButton>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <Table>
          <TableHeadSortable
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeadCells}
          />
          <TableBody style={{ borderRadius: "16px" }}>
            {data?.data.docs.map((auditRecord) => (
              <TableRow key={auditRecord._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {auditRecord.user.email}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {auditRecord.tags
                      .filter((tag) => tagIds.includes(String(tag._id)))
                      .map((tag) => (
                        <Chip key={tag._id} label={tag.tag} size="small" />
                      ))}
                  </Box>
                </TableCell>
                <TableCell>{convertDatetimeStringToReadable(auditRecord.createdAt)}</TableCell>

                <TableCell>{auditRecord.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={data?.data.total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ProgressDialog
        type={ProgressBarType.INFINITE}
        open={downloadProgressOpen}
        onClose={() => setDownloadProgressOpen(false)}
        title="Downloading CSV of users"
      />
    </div>
  );
};

export default UserTargetingAuditList;
