import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "../screens/public/HomeScreen/HomeScreen";
import PublicLayout from "../layouts/PublicLayout/PublicLayout";
import AdminLayout from "../layouts/AdminLayout/AdminLayout";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SurveyList from "../screens/admin/SurveysList/SurveysList";
import EditSurvey from "../screens/admin/EditSurvey/EditSurvey";
import AuthModule from "../screens/auth/AuthModule";
import { AWS_CONFIG, AUTH_CONFIG } from "../config/config";
import UserList from "../screens/admin/UsersList/UsersList";
import UserDetails from "../screens/admin/UserDetails/UserDetails";
import SettingsTab from "../screens/admin/SettingsTab/SettingsTab";
import TestUsers from "../screens/admin/TestUsers/TestUsers";
import SurveyDisplay from "../screens/public/SurveyDisplay/SurveyDisplay";
import ProfileScreen from "../screens/public/Profile/ProfileScreen";
import { SnackbarProvider, closeSnackbar } from "notistack";
import UpdateAdditionalAttributes from "../screens/auth/UpdateAdditionalAttributes";
import NewSurvey from "../screens/admin/NewSurvey/NewSurvey";
import Faq from "../screens/public/FAQ/Faq";
import InvitationsList from "../screens/admin/Researchers/InvitationsList";
import CreateInvitationCodes from "../screens/admin/Researchers/InvitationCodesCreate";
import ResearcherLayout from "src/layouts/ResearcherLayout/ResearcherLayout";
import ResearcherCreateSurvey from "../screens/admin/Researchers/ResearcherCreateSurvey";
import ResearcherEditSurvey from "../screens/admin/Researchers/ResearcherEditSurvey";
import { UserRoles } from "src/types/User";
import { AppPaths } from "src/constants/url";
import SelfServeGuide from "../screens/landing-page/SelfServeGuide/SelfServeGuide";

const AppRoutes = () => {
  const config = AWS_CONFIG;
  const authConfig = AUTH_CONFIG;

  const SnackBarCloseAction = (id: string) => (
    <CloseIcon style={{ cursor: "pointer" }} onClick={() => closeSnackbar(id)} fontSize="small" />
  );

  return (
    <SnackbarProvider
      hideIconVariant={true}
      autoHideDuration={1000 * 60} //1 min
      action={SnackBarCloseAction}
    >
      <AuthModule awsConfig={config} authConfig={authConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PublicLayout />}>
              <Route index element={<HomeScreen />} />
              <Route path="survey/:id" element={<SurveyDisplay />} />
              {/* <Route path="profile" element={<ProfileScreen />} />
              <Route
                path="rewards"
                element={
                  <RewardsPage
                    pointsPerDollar={200}
                    dollarValues={[10, 25, 100]}
                  />
                }
              /> */}
              <Route path="profile" element={<ProfileScreen />}>
                <Route path=":tab" element={<ProfileScreen />} />
              </Route>
              <Route path="profile/redeem-attributes" element={<UpdateAdditionalAttributes />} />
              <Route path="faq" element={<Faq />} />
              {/* <Route path="account-details" element={<AccountDetails />} />
                <Route path="security" element={<ChangePasswordForm />} />
                <Route
                  path="rewards"
                  element={
                    <RewardsPage
                      pointsPerDollar={200}
                      dollarValues={[10, 25, 100]}
                    />
                  }
                />
              </Route> */}
            </Route>
            <Route path="admin" element={<AdminLayout />}>
              <Route path="surveys" element={<SurveyList />} />
              <Route path="surveys/:id" element={<EditSurvey />} />
              <Route path="surveys/:id/researcher-view" element={<ResearcherEditSurvey readOnly />} />
              <Route path="surveys/new" element={<NewSurvey />} />
              <Route path="users" element={<UserList />} />
              <Route path="users/:id" element={<UserDetails />} />
              <Route path="settings" element={<SettingsTab />} />
              <Route path="secret" element={<TestUsers />} />
              <Route path="invitations" element={<InvitationsList />} />
              <Route path="researchers" element={<UserList role={UserRoles.RESEARCHER} />} />
              <Route path="invitations/new" element={<CreateInvitationCodes />} />
            </Route>
            <Route path={AppPaths.researcher.pathSegment} element={<ResearcherLayout />}>
              <Route path={AppPaths.researcher.surveys.pathSegment} element={<SurveyList />} />
              <Route path={AppPaths.researcher.surveys.pathSegment + "/:id"} element={<ResearcherEditSurvey />} />
              <Route
                path={`${AppPaths.researcher.surveys.pathSegment}/:id/payment-callback`}
                element={<ResearcherEditSurvey />}
              />
              <Route path={`${AppPaths.researcher.surveys.pathSegment}/new`} element={<ResearcherCreateSurvey />} />
              <Route path={AppPaths.researcher.profile.pathSegment} element={<ProfileScreen />}>
                <Route path=":tab" element={<ProfileScreen />} />
              </Route>
            </Route>
            <Route path="self-serve-survey-integration-guide" element={<SelfServeGuide />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </AuthModule>
    </SnackbarProvider>
  );
};

export default AppRoutes;
