import React from "react";
import { Alert, Box, Button, Paper, Stack, TextField, Snackbar, FormControl, MenuItem, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UserService from "../../../../services/api/usersService";
import { useValidPhone } from "../../../../hooks/useValidPhone";
import { useValidZip } from "../../../../hooks/useValidZip";
import "../ProfileScreen.scss";
import { genderOptionsArr, isGender } from "src/interfaces/IAuth";
import SnackBarClose from "../../../../components/common/SnackbarClose";
import { User, UserRoles } from "src/types/User";

interface Props {
  userData: Partial<User> | undefined;
  onUpdate: () => void;
}

const AccountDetailsTab = (props: Props) => {
  const { userData, onUpdate } = props;

  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [organization, setOrganization] = React.useState<string | undefined>();

  const [email, setEmail] = React.useState<string>(""); 
  const { phone, setPhone, phoneError, parsedPhone } = useValidPhone("");
  const { zip, setZip, zipError } = useValidZip("");
  const [allFieldsValid, setAllFieldsValid] = React.useState(false);
  const [dob, setDOB] = React.useState<Date | null>(null);
  const [gender, setGender] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [newProfilePic, setNewProfilePic] = React.useState(null);
  const [newImage, setNewImage] = React.useState<string | null>(null);
  const [genericErrorMessage, setGenericErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const isResearcher = userData?.role === UserRoles.RESEARCHER;

  const parseUserData = (userData: Partial<User>) => {
    let params = new FormData();
    if (!userData) return;
    if (firstName !== userData.firstName) params.append("firstName", firstName);
    if (lastName !== userData.lastName) params.append("lastName", lastName);
    if (email !== userData.email) params.append("email", email);
    if (phone !== userData.phone) params.append("phone", parsedPhone);
    if (organization && organization !== userData.organization) params.append("organization", organization);
    if (dob && (!userData.dob || dob.toISOString() !== userData.dob)) params.append("dob", dob.toISOString());
    if (zip !== userData.address?.zip) params.append(`address[zip]`, zip);
    if (gender && gender !== userData.gender) params.append("gender", gender);
    if (newProfilePic) params.append("image", newProfilePic);
    return params;
  };

  const saveChanges = () => {
    setLoading(true);
    if (!userData) return;
    let params = parseUserData(userData);

    if (params && Array.from(params.entries()).length) {
      UserService.updateUserData(params)
        .then((res) => {
          onUpdate();
          setLoading(false);
          setAlertMessage("Account information successfully updated!");
          setNewImage(null);
          setNewProfilePic(null);
        })
        .catch((error) => {
          setGenericErrorMessage(error.response.data.message);
          setOpen(true);
        });
    } else {
      setLoading(false);
    }
  };

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setNewProfilePic(file);
      setNewImage(URL.createObjectURL(file));
    }
  };

  const removeNewImage = () => {
    setNewProfilePic(null);
    setNewImage(null);
  };

  React.useEffect(() => {
    setLoading(true);

    if (!userData) return;

    setFirstName(userData.firstName || "");
    setLastName(userData.lastName || "");
    setEmail(userData.email || "");
    setPhone(userData.phone || "");
    setDOB(userData.dob ? new Date(userData.dob) : null);
    if (userData.address) setZip(userData.address.zip || "");
    setGender(userData.gender || "");
    setOrganization(userData.organization);

    setLoading(false);
  }, [userData, setPhone, setZip]);

  React.useEffect(() => {
    let allValid = !!firstName && !!lastName && phoneError == null;
    if (isResearcher) {
      allValid = allValid && !!organization;
      if (dob) {
        allValid &&= new Date("1900-01-01") < new Date(dob) && new Date(dob) < new Date();
      }
      if (zip) {
        allValid &&= zipError === null;
      }
    } else {
      allValid &&=
        !!gender &&
        dob != null &&
        new Date("1900-01-01") < new Date(dob) &&
        new Date(dob) < new Date() &&
        zipError === null;
    }
    setAllFieldsValid(allValid);
  }, [firstName, lastName, dob, zip, gender, parsedPhone, phoneError, zipError, isResearcher, organization]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleGenderChange = (event: SelectChangeEvent) => {
    if (!isGender(event.target.value)) {
      return;
    }
    setGender(event.target.value);
  };

  return (
    <>
      <Paper
        className="account-details-container"
        sx={{
          boxShadow: "none",
        }}
      >
        {alertMessage ? (
          <Stack sx={{ width: "100%", margin: "-20px 0 40px" }}>
            <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
              {alertMessage}
            </Alert>
          </Stack>
        ) : null}

        <Box className="form-wrapper">
          <Box className="column">
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              value={firstName}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              value={lastName}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => setLastName(event.target.value)}
            />
            <TextField disabled label="Email" variant="outlined" type="email" size="small" value={email} />

            <div className="upload-avatar-container">
              {newImage ? (
                <>
                  <div className="image-wrapper">
                    <img src={newImage} alt="survey-cover" />
                  </div>
                  <Button variant="outlined" onClick={removeNewImage}>
                    Remove Image
                  </Button>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    id="upload-avatar-button"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="upload-avatar-button" className="upload-avatar-label">
                    <FileUploadOutlinedIcon />
                    <p>Upload Image</p>
                  </label>
                </>
              )}
            </div>
            <span>Max image size: 1MB</span>
          </Box>

          <Box className="column">
            <Box className="row">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date Of Birth"
                  value={dob ? new Date(dob) : null}
                  onChange={(newValue: Date | null) => {
                    setDOB(newValue);
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {isResearcher ? (
                <TextField
                  label="Organization"
                  variant="outlined"
                  size="small"
                  value={organization ?? ""}
                  inputProps={{ maxLength: 50 }}
                  onChange={(event) => setOrganization(event.target.value)}
                />
              ) : null}
            </Box>
            <Box className="row">
              <TextField
                label="Zip Code"
                variant="outlined"
                size="small"
                value={zip}
                error={zip ? zipError !== null : zipError !== null && !isResearcher}
                onChange={(event) => {
                  setZip(event.target.value);
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="gender-select-label" size="small">Gender</InputLabel>
                <Select
                  size="small"
                  labelId="gender-select-label"
                  id="gender-select"
                  label="Gender"
                  value={gender ?? ""}
                  style={{ textTransform: "capitalize" }}
                  onChange={handleGenderChange}
                >
                  {genderOptionsArr.map((genderOption) => (
                    <MenuItem 
                      value={genderOption} 
                      key={genderOption} 
                      data-testid="gender-input-options"
                      style={{ textTransform: "capitalize" }}
                    >
                      {genderOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Phone"
              variant="outlined"
              size="small"
              value={phone}
              error={phoneError != null}
              onChange={(event) => setPhone(event.target.value)}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          onClick={saveChanges}
          disabled={loading || !allFieldsValid}
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#2EBDEE",
            border: "1px solid #2EBDEE",
            borderRadius: "4px",
            padding: "10px 70px",
            margin: "40px 0",
            "&:hover": {
              background: "#2EBDEE",
              opacity: "0.7",
            },
            "&:disabled": {
              border: "1px solid #91a3a9",
            },
          }}
        >
          Save Changes
        </Button>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={genericErrorMessage}
        action={<SnackBarClose handleClose={handleClose} />}
      />
    </>
  );
};

export default AccountDetailsTab;
