import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import "./QuestionDialog.scss";

const QuestionDialog = (props: any) => {
  const {
    onClose,
    onSubmit,
    open,
    text,
    disabledYesButton = false,
    icon = false,
    iconImg = "",
    iconClass = "",
    "data-testid": testId,
    yesButtonText = "Yes",
    noButtonText = "No",
  } = props;
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { borderRadius: 28, maxWidth: "700px" },
        }}
      >
        <div data-testid={testId} className="question-dialog-content">
          {icon && <img src={iconImg} alt="icon" className={iconClass} />}
          <div className="dialog-title">{text}</div>
          <div className="dialog-text">{props.children}</div>
          <div className="buttons-wrapper">
            <Button
              data-testid={`${testId}-btn-no`}
              color="primary"
              variant="contained"
              onClick={() => onSubmit(false)}
              className="dialog-decline-button"
            >
              {noButtonText}
            </Button>
            <Button
              data-testid={`${testId}-btn-yes`}
              color="primary"
              variant="contained"
              onClick={() => onSubmit(true)}
              className="dialog-submit-button"
              disabled={disabledYesButton}
              style={disabledYesButton ? { backgroundColor: "rgb(179 236 255)" } : {}}
            >
              {yesButtonText}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default QuestionDialog;
