import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Button } from "@material-ui/core";
import { LinearProgress } from "@mui/material";

import {
  backgroundStyle,
  btnStyle,
  h1Style,
  logoStyle,
  paperStyle,
  rowStyle,
  containerStyle,
} from "../../../styles/auth.styles";
import TopicItem from "./TopicItem";
import UsersService from "../../../services/api/usersService";
import verasightLogo from "../../../assets/landing-page/verasight-logo-community.svg";
import { TOPICS } from "./constants";
import type { IInterestResponseData, ITopic } from "./types";
import { AuthContext } from "../../../contexts/authContext";
import SideCarousel from "../../../components/SignUpSideCarousel/SignUpSideCarousel";
import { completedInterests } from "../../../helpers/dataLayers";
import TikTokPixelUtil from "../../../services/tiktokUtil";

const SelectTopics: React.FC<any> = () => {
  const navigation = useNavigate();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [topics, setTopics] = useState<({ id: string } & ITopic)[]>([]);
  const authContext = useContext(AuthContext);

  const fetchInterests = useCallback(async () => {
    try {
      const res = await UsersService.getInterests();
      if (Array.isArray(res.data)) {
        const data: IInterestResponseData[] = res.data;

        setTopics(
          data
            .filter(({ active }) => active)
            .map(({ _id: id, name }) => {
              const key = name.toLowerCase();
              const existingTopic = TOPICS[key] || {};
              return {
                id,
                name,
                icon: existingTopic.icon,
              };
            })
        );
      }
    } catch (e) {
      console.log("Failed to fetch interests: ", e);
    }
  }, []);

  const handleToggleSelect = (topic: string) => () =>
    setSelectedTopics((v) => (v.includes(topic) ? v.filter((t) => t !== topic) : [...v, topic]));

  const handleNext = async (e: any) => {
    const { signUpUserData, signInWithEmail } = authContext;
    if (signUpUserData && signInWithEmail && authContext.refreshUser) {
      const { id, email, passwordAdvance, utmCode } = signUpUserData;
      if (id && email && passwordAdvance) {
        await UsersService.setInterests(id, { interests: selectedTopics });
        completedInterests(email, selectedTopics.toString());
        TikTokPixelUtil.setTracking({
          tracking: "CompleteRegistration",
          data: {
            userEmail: email,
            utmCode,
          },
        });
        await signInWithEmail(email, passwordAdvance, () => {});
        await authContext.refreshUser(true);
        navigation("/");
      }
    }
  };

  useEffect(() => {
    if (Object.keys(authContext.signUpUserData as object).length === 0) {
      navigation("/");
      return;
    }
    fetchInterests();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <Paper elevation={1} style={paperStyle}>
          <Grid style={{ ...logoStyle, justifyContent: "start" }}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <LinearProgress variant="determinate" value={100} style={{ color: "#2EBDEE" }} />
          <h1 style={h1Style}>Select your topics of interest</h1>
          <div
            style={{
              ...rowStyle,
              marginTop: "40px",
              flexWrap: "wrap",
              rowGap: "24px",
            }}
          >
            {topics.map((topic) => (
              <div style={{ flex: "1 1 45%", minWidth: "250px" }} key={topic.name}>
                <TopicItem
                  data={topic}
                  isSelected={selectedTopics.includes(topic.name)}
                  onClick={handleToggleSelect(topic.name)}
                />
              </div>
            ))}
          </div>
          <div style={{ ...rowStyle, marginTop: "60px" }}>
            <div style={{ flex: "1 1 60%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnStyle}
                fullWidth
                onClick={handleNext}
                disabled={false}
              >
                Next
              </Button>
            </div>
            <div style={{ flex: "1 1 30%" }}></div>
          </div>
        </Paper>
      </div>

      <SideCarousel />
    </div>
  );
};

export default SelectTopics;
