import { Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ActivationRedirect } from "src/types/Survey";

interface Props {
  activationRedirect?: ActivationRedirect;
  readOnly?: boolean;
}
export default function RedirectVerification({ activationRedirect, readOnly }: Readonly<Props>) {
  if (!activationRedirect) {
    return null;
  }
  
  if (activationRedirect?.activationLink) {
    const linkText = "Complete to activate";
    return (
      <>
        {readOnly ? (<span style={{opacity: .6}}>{linkText}</span>) 
        : <Link underline="hover" target="_self" rel="noopener noreferrer" href={activationRedirect?.activationLink}>
            {linkText}
          </Link>}
      </>
    );
  }
  return <CheckCircleIcon sx={{ color: "green", marginLeft: "5px" }} />;
}
