import { convertToArray } from "src/helpers/array";
import { matrixQuestionMultiplier } from "src/constants/prices";
import { NotificationSchedule, PointsTableData, RedirectPointsData, SurveyLimits, SurveyType } from "src/types/Survey";
import { TargetingTag, UTM_TARGETING_TAG_PREFIX } from "src/types/TargetingTag";
import { UserRoles } from "src/types/User";
import { SurveyDisplayStatuses } from "src/constants/survey";

export const getRedirectLinkPoints = (externalSurveyRedirectPointsData: RedirectPointsData) => {
  const redirectLinkPoints = externalSurveyRedirectPointsData.parameters
    ? externalSurveyRedirectPointsData.parameters.map((linkData: PointsTableData) => {
        return parseInt(linkData.parameterName ?? "0");
      })
    : [0];
  return redirectLinkPoints;
};

export const getQuestionCount = (limits: SurveyLimits): number => {
  return Math.ceil(
    limits.matrixQuestions * matrixQuestionMultiplier + limits.multipleChoiceQuestions + limits.openEndedQuestions
  );
};

export const isSurvey2CreateValid = (survey: Partial<SurveyType>): boolean => {
  if (!survey.title) {
    return false;
  }
  if (survey.limits) {
    const count = getQuestionCount(survey.limits);
    if (count < 1 || count > 50 || survey.limits.sampleSize < 1) return false;
  }
  return true;
};

export const getUtmCampaignTargetingTags = (survey: SurveyType): Array<string> =>
  Array.isArray(survey.utmCampaignTargetingTags)
    ? survey.utmCampaignTargetingTags
    : survey.utmCampaignTargetingTag
    ? [survey.utmCampaignTargetingTag]
    : [];

export const getNotificationTargetingTags = (scheduleOptions?: NotificationSchedule["scheduleOptions"]) =>
  scheduleOptions?.notificationTags ?? convertToArray(scheduleOptions?.notificationsTagValue) ?? [];

export const santizeUtmTag = (tag: TargetingTag): string => tag.tag.split(UTM_TARGETING_TAG_PREFIX)[1] ?? tag.tag;

export const getDisplayStatus = (survey: SurveyType): string => {
  if (survey.createdByRole === UserRoles.RESEARCHER) {
    if (survey.responsesWeightReport) {
      return SurveyDisplayStatuses.Completed;
    }
    if (survey.end && new Date(survey.end) < new Date()) {
      return SurveyDisplayStatuses.PendingWeights;
    }
  }
  return survey.active ? SurveyDisplayStatuses.Active : SurveyDisplayStatuses.Inactive;
};
