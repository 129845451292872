// import { Theme } from "@react-navigation/native";
import { Dispatch, SetStateAction } from "react";
import { User } from "src/types/User";
import { IAuthAWS } from "./IAuthAWS";
import { IAuthFunctionalities } from "./IAuthFunctionalities";
import { ITheme } from "./ITheme";
import { CashoutVendorEnum } from "../types/generic";

export const genderOptionsArr = ["male", "female", "other"] as const;
export type Gender = typeof genderOptionsArr[number];
export const isGender = (item: any): item is Gender => genderOptionsArr.includes(item);

export type UTMCodeType = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
  utmTerm: string;
};

export type SignUpUserData = Partial<{
  id: string;
  passwordAdvance: string;
  email: string;
  lastName: string;
  zip: string;
  firstName: string;
  dob: Date;
  gender: Gender;
  phone: string;
  venmoPhone: string;
  payPalEmail: string;
  giftCardEmail: string;
  utmCode: UTMCodeType | null;
  inviteCode?: string;
  organization?: string;
  ref?: string | null;
  role?: string;
}>;

export type RedeemUserData = Partial<{
  points: number;
  dollarValue: number;
  vendor: CashoutVendorEnum;
  method: "email" | "phone";
  isNotRedeemed: boolean;
  recaptchaToken: string;
}>;

export enum AuthStatus {
  LOADING = "LOADING",
  SIGNED_IN = "SIGNED_IN",
  SIGNED_OUT = "SIGNED_OUT",
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
    idToken?: string;
  };
  globalConfig?: {
    theme?: ITheme;
    authConfig?: IAuthFunctionalities;
    config?: IAuthAWS;
  };
  user?: Partial<User>;
  refreshUser?: (login: boolean) => Promise<any>;
  signUpUserData?: SignUpUserData;
  setSignUpUserData?: Dispatch<SetStateAction<SignUpUserData>>;
  loadSignupUserDataFromStorageIfSignedUp?: () => Promise<void | boolean>;
  redeemUserData?: RedeemUserData;
  setRedeemUserData?: Dispatch<SetStateAction<RedeemUserData>>;
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: (
    username: string,
    password: string,
    callback: () => void,
    shouldChangeAuthStatus?: boolean
  ) => Promise<any>;
  signInWithEmailnoRedirect?: any;
  signUpWithEmail?: any;
  signUpWithPhone?: any;
  signOut?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  setAttributes?: any;
  enableMFA?: any;
  reSendCode?: any;
  sendCodeRecoverPassword?: any;
  sendMfaCode?: any;
  mfaRequired?: boolean;
  reSendMfaCode?: any;
  federatedSignIn?: any;
  rememberDevice?: any;
  forgotDevice?: any;
  forgotPasswordToken?: any;
  storeData?: any;
  onConfirmCodeResendCodePhone?: any;
  onConfirmCodeSubmittedPhone?: any;
  onConfirmCodeSubmitted?: any;
  onChangePhoneSubmitted?: any;
  setAuthStatus?: React.Dispatch<React.SetStateAction<AuthStatus>>;
}
