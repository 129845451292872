import { Button } from "@material-ui/core";
import { Box, CircularProgress, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { capitalize } from "lodash";
import { useSnackbar } from "notistack";
import SignupLayout from "src/components/Signup/SignupLayout";
import { useValidZip } from "src/hooks/useValidZip";
import { Gender, genderOptionsArr, isGender, UTMCodeType } from "src/interfaces/IAuth";
import InvitationsService from "src/services/api/invitationsService";
import SignUpSideResearchers from "../../components/SIgnUpSideResearchers/SIgnUpSideResearchers";
import { AuthContext } from "../../contexts/authContext";
import { visitRegistrationScreen } from "../../helpers/dataLayers";
import { useValidEmail } from "../../hooks/useValidEmail";
import UsersService from "../../services/api/usersService";
import {
  btnStyle,
  btnStyleDisabled,
  columnStyle,
  hiddenStyle,
  inputStyle,
  plabelStyle,
  rowStyle,
} from "../../styles/auth.styles";

const SignUp: React.FunctionComponent = () => {
  /* States and context declaration */
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const initialData = authContext.signUpUserData;
  const { email, setEmail, emailError } = useValidEmail(initialData?.email ?? "");
  const [firstName, setFirstName] = useState(initialData?.firstName ?? "");
  const [lastName, setLastName] = useState(initialData?.lastName ?? "");
  const [dob, setDOB] = useState(initialData?.dob ?? null);
  const [organization, setOrganization] = useState<string | undefined>(initialData?.organization);
  const [researcherInviteCode, setResearcherInviteCode] = useState<string | undefined>(initialData?.inviteCode);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    visitRegistrationScreen();
  }, []);

  const { zip, setZip, zipError } = useValidZip(initialData?.zip ?? "");
  const initialGender = initialData?.gender!;
  const [gender, setGender] = useState<Gender | null>(genderOptionsArr.includes(initialGender) ? initialGender : null);

  const navigation = useNavigate();
  const [searchParams] = useSearchParams();

  const getUtmData = () => {
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmContent = searchParams.get("utm_content");
    const utmTerm = searchParams.get("utm_term");

    if (!utmSource && !utmMedium && !utmCampaign && !utmContent && !utmTerm) {
      return null;
    }

    const utmCode: UTMCodeType = {
      utmSource: utmSource ?? "no data provided",
      utmMedium: utmMedium ?? "no data provided",
      utmCampaign: utmCampaign ?? "no data provided",
      utmContent: utmContent ?? "no data provided",
      utmTerm: utmTerm ?? "no data provided",
    };

    return utmCode;
  };

  const onNextClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const utmCode = getUtmData();

    authContext.setSignUpUserData?.({
      firstName,
      lastName,
      email: email ?? undefined,
      dob: dob ?? undefined,
      zip,
      gender: gender ?? undefined,
      payPalEmail: email ?? undefined,
      giftCardEmail: email ?? undefined,
      utmCode,
      inviteCode: researcherInviteCode,
      organization,
      ref: searchParams.get("ref"),
    });
    navigation(`/register/select-password`);
  };

  const handleGenderChange = (event: SelectChangeEvent) => {
    if (!isGender(event.target.value)) {
      return;
    }
    setGender(event.target.value);
  };

  React.useEffect(() => {
    const commonFieldsValid = !!firstName && !!lastName && emailError == null;
    if (researcherInviteCode?.length) {
      //researcher
      setAllFieldsValid(commonFieldsValid && !!organization);
    } else {
      setAllFieldsValid(
        commonFieldsValid &&
          !!gender &&
          dob != null &&
          new Date("1900-01-01") < dob &&
          dob < new Date() &&
          zipError == null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, firstName, lastName, dob, zip, zipError, emailError, gender, researcherInviteCode, organization]);

  React.useEffect(() => {
    const ref = searchParams.get("ref");
    if (ref) {
      UsersService.getInviteByRef(ref).then((response) => {
        if (!response.data.accountCreated) {
          setEmail(response.data.email || "");
        }
      });
    } else {
      const researcherInviteCode = searchParams.get("researcherInviteCode");
      if (researcherInviteCode?.length) {
        setResearcherInviteCode(researcherInviteCode);
        setIsLoading(true);
        InvitationsService.validate(researcherInviteCode)
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            enqueueSnackbar("Invalid Invite code", { variant: "error" });
            navigation("/register/researcher", { replace: true });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setEmail]);

  React.useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStorageData = async () => {
    if (await authContext.loadSignupUserDataFromStorageIfSignedUp?.()) {
      navigation(`/register/verify-phone`);
    }
  };
  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress sx={{ color: "pink" }} />
    </Box>
  ) : (
    <SignupLayout
      Carousel={researcherInviteCode?.length ? <SignUpSideResearchers /> : undefined}
      title="Sign Up"
      progress={20}
      notice={
        researcherInviteCode?.length
          ? "Welcome to Verasight's Research Portal! Register below to launch your survey."
          : "Welcome to Verasight! Register below to earn Venmo payments or gift cards for taking short surveys!"
      }
    >
      <div data-testid="sign-up" style={rowStyle}>
        <div style={columnStyle}>
          <p style={plabelStyle}>First Name</p>
          <OutlinedInput
            size="small"
            placeholder="First Name"
            fullWidth
            required
            value={firstName}
            style={inputStyle}
            inputProps={{ maxLength: 50 }}
            onChange={(e: any) => setFirstName(e.target.value)}
            data-testid="first-name-input"
          />
        </div>
        <div style={columnStyle}>
          <p style={plabelStyle}>Last Name</p>
          <OutlinedInput
            size="small"
            placeholder="Last Name"
            fullWidth
            required
            value={lastName}
            style={inputStyle}
            inputProps={{ maxLength: 50 }}
            onChange={(e: any) => setLastName(e.target.value)}
            data-testid="last-name-input"
          />
        </div>
      </div>
      <div style={rowStyle}>
        <div style={columnStyle}>
          <p style={plabelStyle}>Email Address</p>
          <OutlinedInput
            size="small"
            placeholder="Email"
            fullWidth
            required
            value={email}
            style={inputStyle}
            onChange={(e: any) => setEmail(e.target.value)}
            error={emailError != null}
            data-testid="email-input"
          />
        </div>
        <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle} data-testid="dob-input">
          <p style={plabelStyle}>Date of Birth</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              sx={{ width: "100%" }}
              value={dob ? new Date(dob) : null}
              views={["year", "month", "day"]}
              onChange={(newValue: React.SetStateAction<Date | null>) => {
                setDOB(newValue);
              }}
              disableFuture={true}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div style={rowStyle}>
        <div
          style={{
            ...rowStyle,
            ...columnStyle,
            flexWrap: "nowrap",
            minWidth: "275px",
          }}
        >
          <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle}>
            <p style={plabelStyle}>Zip Code</p>
            <OutlinedInput
              size="small"
              placeholder="Zip Code"
              fullWidth
              required
              value={zip}
              error={zipError != null}
              style={{ ...inputStyle, minWidth: "unset" }}
              onChange={(e) => setZip(e.target.value)}
              data-testid="zip-input"
            />
          </div>
          <div style={researcherInviteCode?.length ? columnStyle : hiddenStyle}>
            <p style={plabelStyle}>Organization</p>
            <OutlinedInput
              size="small"
              placeholder="Organization"
              fullWidth
              required
              value={organization}
              style={inputStyle}
              inputProps={{ maxLength: 50 }}
              onChange={(e: any) => setOrganization(e.target.value)}
              data-testid="organization-input"
            />
          </div>
          <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle}>
            <p style={plabelStyle}>Gender</p>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender ?? ""}
                onChange={handleGenderChange}
                data-testid="gender-input"
              >
                {genderOptionsArr.map((genderOption) => (
                  <MenuItem value={genderOption} key={genderOption} data-testid="gender-input-options">
                    {capitalize(genderOption)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div style={{ ...rowStyle, marginTop: "60px" }}>
        <div style={{ flex: "1 1 30%" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              ...btnStyle,
              backgroundColor: "white",
              color: "#5F6F74",
            }}
            fullWidth
            onClick={(e) => {
              navigation(-1);
            }}
            data-testid="back-button"
          >
            Back
          </Button>
        </div>
        <div style={{ flex: "1 1 60%" }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={allFieldsValid ? btnStyle : btnStyleDisabled}
            disabled={!allFieldsValid}
            onClick={onNextClicked}
            data-testid="submit-button"
          >
            Next
          </Button>
        </div>
      </div>
    </SignupLayout>
  );
};

export default SignUp;
