import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/authContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "@material-ui/core";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import {
  alertStyle,
  btnStyle,
  rowStyle,
  inputStyle,
  phoneButtonStyle,
  phoneErrorStyle,
  plabelStyle,
  columnStyle,
  mfaBlockStyle,
  mfaCheckboxStyle,
  mfaTextStyle,
  mfaAdditionalInfo,
} from "../../styles/auth.styles";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Checkbox, Typography } from "@mui/material";
import { setItemIDB } from "../../services/indexedDb";
import { UserRoles } from "src/types/User";
import { useValidPhone } from "src/hooks/useValidPhone";
import UsersService from "src/services/api/usersService";
import ReCaptcha from "src/components/common/ReCaptcha";
import { getErrorMessage } from "src/helpers/errors";
import { StorageKeys } from "src/constants/storage";
import SignupLayout from "src/components/Signup/SignupLayout";
import QuestionDialog from "src/components/QuestionDialog/QuestionDialog";

const CreateAccount: React.FC<any> = () => {
  const authContext = useContext(AuthContext);
  const { signUpUserData } = authContext;
  const { phone, setPhone, phoneError, parsedPhone } = useValidPhone(signUpUserData?.phone ?? "");
  const [mfaChecked, setMfaChecked] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);

  let resetCaptcha: (() => void) | undefined;

  const handleResetCaptcha = () => {
    if (resetCaptcha) {
      resetCaptcha();
    }
  };

  const navigation = useNavigate();

  const [genericErrorMessage, setGenericErrorMessage] = useState("");

  useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResetCaptcha();
  }, [parsedPhone]);

  const handleMfaCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaChecked(event.target.checked);
  };

  if (!signUpUserData) {
    return null;
  }

  const getStorageData = async () => {
    if (await authContext.loadSignupUserDataFromStorageIfSignedUp?.()) {
      navigation(`/register/verify-phone`);
    } else {
      setPhone(signUpUserData.phone || "");
    }
  };

  const onCreateAccount = async (create: boolean) => {
    const {
      email,
      gender,
      zip,
      dob,
      firstName,
      lastName,
      payPalEmail,
      giftCardEmail,
      utmCode,
      organization,
      inviteCode,
      passwordAdvance,
      ref,
    } = signUpUserData;
    let { venmoPhone } = signUpUserData;
    const phone = parsedPhone;
    venmoPhone = venmoPhone ?? phone;

    authContext.setSignUpUserData?.((prevData) => ({
      ...prevData,
      phone: parsedPhone,
      venmoPhone,
    }));
    if (!create) {
      navigation(`/register/additional-attributes`);
      return;
    }
    setOpenQuestionDialog(false);

    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      ((dob && zip && gender) || (organization && inviteCode)) &&
      passwordAdvance
    ) {
      try {
        const payload = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          dob,
          ...(zip && {
            address: {
              zip,
            },
          }),
          phone,
          role: inviteCode?.length ? UserRoles.RESEARCHER : UserRoles.USER,
          gender,
          ref,
          active: true,
          payPalEmail,
          venmoPhone,
          giftCardEmail,
          password: passwordAdvance,
          utmCode,
          organization,
          inviteCode,
          recaptchaToken,
        };
        const createdUser = await UsersService.registerCognitoUser(payload);
        if (authContext.setSignUpUserData) {
          authContext.setSignUpUserData((prevData) => ({
            ...prevData,
            phone,
            venmoPhone,
            role: createdUser.data.role,
            id: createdUser.data._id,
          }));
        } else {
          throw new Error("Failed to set password in state");
        }
        await setItemIDB(StorageKeys.isOTPScreenOn, "1");
        await setItemIDB(
          StorageKeys.signUpUserData,
          JSON.stringify({
            ...authContext.signUpUserData,
            phone,
            venmoPhone,
            role: createdUser.data.role,
            id: createdUser.data._id,
          })
        );
        navigation("/register/verify-phone");
      } catch (error: any) {
        if (error.response) {
          let { statusCode, message: messageObj, error: err } = error.response.data || {};
          let { message } = messageObj || {};

          statusCode ??= error.response.status;
          message ??= messageObj;
          err ??= messageObj?.error;

          if (err?.name === "UsernameExistsException") {
            setGenericErrorMessage("An account with this email address already exists.");
          } else if (statusCode === 500) {
            setGenericErrorMessage("The phone number must be from USA");
          } else {
            setGenericErrorMessage(message);
          }
        } else {
          setGenericErrorMessage(getErrorMessage(error));
        }
        handleResetCaptcha();
      }
    } else {
      navigation("/register");
    }
  };

  return (
    <>
      <SignupLayout progress={80} title="Create Account">
        <div style={columnStyle} data-testid="phone-input">
          <p style={plabelStyle}>Mobile Number</p>
          <PhoneInput
            country={"us"}
            onlyCountries={["us"]}
            value={phone}
            inputStyle={inputStyle}
            buttonStyle={phoneButtonStyle}
            onChange={(phone) => setPhone(phone)}
            placeholder="Mobile Number"
            countryCodeEditable={false}
          />
          {phoneError && <p style={phoneErrorStyle}>{phoneError}</p>}
          {genericErrorMessage && (
            <Alert style={alertStyle} variant="outlined" severity="error">
              Error: {genericErrorMessage}
            </Alert>
          )}
        </div>
        <div style={mfaBlockStyle}>
          <Checkbox data-testid="check-mfa" checked={mfaChecked} onChange={handleMfaCheckbox} sx={mfaCheckboxStyle} />
          <div style={mfaTextStyle}>I agree to receive a verification code via SMS</div>
        </div>
        <p style={mfaAdditionalInfo}>
          By providing your phone number, you are consenting to receive calls and text messages, including autodialed
          and automated calls and texts, to that number from Verasight. Message and data rates may apply. Reply "STOP"
          to opt-out. Terms & conditions/privacy policy apply:{" "}
          <a style={{ textDecoration: "none", color: "rgb(46, 189, 238)" }} href="https://www.verasight.io/privacy?">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a style={{ textDecoration: "none", color: "rgb(46, 189, 238)" }} href="https://www.verasight.io/terms?">
            Terms and Condition
          </a>
          <span>.</span>
        </p>
        <ReCaptcha setRecaptchaToken={setRecaptchaToken} getResetRef={(reset) => (resetCaptcha = reset)} />
        <div style={{ ...rowStyle, marginTop: "60px" }}>
          <div style={{ flex: "1 1 60%" }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnStyle}
              fullWidth
              onClick={() => setOpenQuestionDialog(true)}
              disabled={!phone || phoneError !== null || !mfaChecked || !recaptchaToken}
            >
              Register & Verify Phone
            </Button>
          </div>
        </div>
      </SignupLayout>
      <QuestionDialog
        onClose={() => setOpenQuestionDialog(false)}
        onSubmit={onCreateAccount}
        open={openQuestionDialog}
        noBtnStyle={{
          ...btnStyle,
          backgroundColor: "white",
          color: "#5F6F74",
        }}
        data-testid="dialog-paypal-email-confirm"
        yesBtnStyle={btnStyle}
        yesButtonText="Let's create my account"
        noButtonText="I want them to be different"
        text="Verasight sends cash rewards to your PayPal or Venmo accounts. Are these the email and phone number you use for your PayPal and Venmo accounts? If not, let us know what email and phone you use for your PayPal and Venmo accounts."
      >
        <Box sx={{ p: 2 }}>
          <Box display="flex" alignItems="center" gap={2} mb={2}>
            <EmailIcon color="primary" />
            <Typography variant="body1">{signUpUserData.payPalEmail}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <PhoneIcon color="primary" />
            <Typography variant="body1">{signUpUserData.venmoPhone ?? phone}</Typography>
          </Box>
        </Box>
      </QuestionDialog>
    </>
  );
};

export default CreateAccount;
