import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getPathBase } from "src/helpers/urlHelper";
import { AuthContext } from "src/contexts/authContext";
import { MainButton } from "src/components/VeraButton";
import IntegrationGuideLink from "src/components/IntegrationGuideLink/IntegrationGuideLink";
import emptyDashboard from "src/assets/empty-dashboard.png";
import "./ResearcherEmptyDashboard.scss";


export default function ResearcherEmptyDashboard() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  
  return (
    <div className="dashboard-container">
      <div className="empty-dashboard">
        <img src={emptyDashboard} alt="Empty Dashboard" />
        <h2>No surveys available</h2>
        <p>Create a new survey to get started!</p>
        <MainButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/${getPathBase(user?.role)}/surveys/new`)}
          sx={{ width: "80%", marginBottom: "1rem" }}
        >
          Create New Survey
        </MainButton>
        <IntegrationGuideLink text="Learn More" />
      </div>
    </div>
  );
}