import { Chip } from "@material-ui/core";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BootstrapInput from "../../../../components/BootstrapInput";
import { ChangeEventHandler, useEffect, useState } from "react";
import "./LeftColumnFields.scss";
import SurveysService from "../../../../services/api/surveysService";
import CustomDateTimePicker from "src/components/DateTimePicker/DateTimePicker";
import TZPicker from "src/components/TZPicker/TZPicker";
import { PickersTimezone } from "@mui/x-date-pickers";

const interests = ["Art", "Economy", "Environment", "Science", "Sports", "Politics"];

type Setter<T> = (arg: T) => void;

interface Props {
  tags: string[] | undefined;
  setTags: Setter<string[]>;
  points: number | null;
  setPoints: Setter<number | null>;
  startDate: string | null;
  endDate: string | null;
  setStartDate: Setter<string | null>;
  setEndDate: Setter<string | null>;
  title: string;
  setTitle: Setter<string>;
  category: string | null;
  setCategory: Setter<string>;
  enableSpinner: boolean;
  setEnableSpinner: Setter<boolean>;
  onChangeSpinnerPercentage: ChangeEventHandler<HTMLInputElement>;
  spinnerPercentage: number | null;
  status: boolean;
  setStatus: Setter<boolean>;
  saveSurveyChanges?: Setter<boolean | undefined>;
  isResearcherSurvey?: boolean;
  isLoading: boolean;
  hasRequiredTitle?: boolean;
}

const LeftColumnFields = ({
  tags,
  setTags,
  points,
  setPoints,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  title,
  setTitle,
  category,
  setCategory,
  enableSpinner,
  setEnableSpinner,
  onChangeSpinnerPercentage,
  spinnerPercentage,
  status,
  setStatus,
  saveSurveyChanges,
  isResearcherSurvey,
  isLoading,
  hasRequiredTitle = true,
}: Props) => {
  const [availableTags, setAvailableTags] = useState<any[]>([]);
  const [timezone, setTimeZone] = useState<PickersTimezone>("America/Los_Angeles");

  const onChangeEndDate = (newValue: string | null) => {
    setEndDate(newValue);
  };

  const onChangeRewardPoints = (event: any) => {
    if (event.target.value === "") {
      setPoints(null);
      return;
    }
    const points = +event.target.value.replace(/[^0-9.]/g, "");
    setPoints(isNaN(points) ? null : points);
  };

  const onChangeCategories = (_event: any, newValue: any) => {
    setCategory(category === newValue ? null : newValue);
  };

  const onChangeStatus = (event: any) => {
    setStatus(event.target.value === "1");
  };

  useEffect(() => {
    SurveysService.getTags().then((res) => {
      const tags = res.data.map((tag: any) => tag.name);

      setAvailableTags(tags);
    });
  }, []);

  return (
    <div>
      <Box className="edit-survey-row">
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Title*</div>
          <BootstrapInput 
            value={title} 
            disabled={isResearcherSurvey}
            onChange={(event) => setTitle(event.target.value)} 
            data-testid="title" 
          />
          {!title && !hasRequiredTitle ? <p className="required-field-label">Required field</p> : null}
        </FormControl>
      </Box>

      <Box className="edit-survey-row data-block-container">
        <div className="input-label">Categories</div>
        <Autocomplete
          id="categories-select"
          options={interests}
          value={category}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          size="small"
          onChange={onChangeCategories}
          renderInput={(params) => <TextField {...params} placeholder="Categories" />}
        />
      </Box>

      <Box className="edit-survey-row data-block-container">
        <div className="input-label">Tags</div>
        <Autocomplete
          multiple
          id="tags"
          options={availableTags}
          value={tags}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => <TextField {...params} placeholder="Tags" size="small" />}
          style={{ textTransform: "capitalize" }}
          size="small"
          onChange={(_event: any, newValue: any) => setTags(newValue)}
        />
      </Box>

      <Box className="edit-survey-row">
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Reward Points</div>
          <BootstrapInput value={points} type="number" onChange={onChangeRewardPoints} />
        </FormControl>
      </Box>

      <Box className="edit-survey-row">
        <Box sx={{ display: "flex", columnGap: "10px" }}>
          <div style={{ border: "1px dotted gray" }} className="input-container-survey">
            <Stack margin={2} spacing={2}>
              <TZPicker onTZChange={setTimeZone} />
              <div className="date-picker-lable input-label">Start Date</div>
              <CustomDateTimePicker
                currentTimezone={timezone}
                value={startDate ? new Date(startDate) : null}
                setValue={(newValue: any) => {
                  setStartDate(newValue);
                  setEndDate(null);
                }}
              />
              <div className="input-label date-picker-lable">End Date</div>
              <CustomDateTimePicker
                currentTimezone={timezone}
                value={endDate ? new Date(endDate) : null}
                setValue={(newValue: any) => {
                  onChangeEndDate(newValue);
                }}
                minDate={startDate ? new Date(startDate) : undefined}
              />
            </Stack>
          </div>
        </Box>
      </Box>

      <Box className="edit-survey-row input-container-survey">
        <div className="input-label">Status</div>
        <TextField value={status ? "1" : "0"} select size="small" onChange={onChangeStatus} className="status-input">
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">Inactive</MenuItem>
        </TextField>
      </Box>

      <div className="last-container">
        <Box className="multiplier-block">
          <h6 className="targeting-titles">Hit multiplier (Spinning Wheel)</h6>
          <FormGroup>
            <FormControlLabel
              className="checkbox-label"
              sx={{ marginLeft: 0 }}
              label="Allow hit multiplier in this survey"
              control={<Checkbox checked={enableSpinner} onChange={(e) => setEnableSpinner(e.target.checked)} />}
              labelPlacement="start"
            />
          </FormGroup>
          <h6 className="targeting-titles">Win rate</h6>
          <div className="multiplier-container">
            <TextField
              disabled={!enableSpinner}
              variant="outlined"
              size="small"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ marginRight: "-30px" }} position="end">
                    %
                  </InputAdornment>
                ),
              }}
              value={spinnerPercentage}
              onChange={onChangeSpinnerPercentage}
            />
          </div>
        </Box>

        {typeof saveSurveyChanges === "function" ? (
          <button
            type="button"
            onClick={() => saveSurveyChanges(undefined)}
            disabled={isLoading}
            className="last-container__button"
            data-testid="btn-submit"
          >
            {isLoading ? "Loading..." : "Save Information"}
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default LeftColumnFields;
