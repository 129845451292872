import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/authContext";
import { Link as Linker, useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Paper, TextField, Link, Typography } from "@material-ui/core";
import {
  backgroundStyle,
  backgroundStyleCenter,
  btnStyle,
  h3Style,
  inputStyle,
  logoStyle,
  paperStyle,
  pStyle,
  TypographyStyle,
} from "../../styles/auth.styles";
import { useCountDown } from "../../hooks/useCountDown";
import { useValidCode } from "../../hooks/useValidCode";
import verasightLogo from "../../assets/logo.svg";
const VerifyEmail: React.FC<any> = () => {
  /* States and context declaration */
  const { email } = useParams();

  const authContext = useContext(AuthContext);

  const { code, setCode, codeIsValid } = useValidCode("");
  const [, setError] = useState("");

  const navigation = useNavigate();
  const { setSeconds, setIsActive } = useCountDown(15);

  /* Generic Error Message */
  const [, setGenericErrorMessage] = useState("");

  /* 
  Functions Section 
  */

  const onReSendCodeCallback = (isReSended: boolean) => {
    if (!isReSended) {
      setGenericErrorMessage("Ooops something was wrong please login again");
      setTimeout(function () {
        navigation("/login");
      }, 4000);
    }
  };

  const onReSendCode = async (e: any) => {
    e.stopPropagation();
    setSeconds(15);
    setIsActive(true);

    if (authContext.mfaRequired)
      try {
        const codeSended = await authContext.reSendMfaCode(onReSendCodeCallback);
        console.log(codeSended);
      } catch (error: any) {
        setGenericErrorMessage(error.message);
      }
    else
      try {
        await authContext.reSendCode(email);
      } catch (error: any) {
        setGenericErrorMessage(error.message);
      }
  };

  const sendCode = async (e: any) => {
    e.stopPropagation();

    if (code) {
      if (code.length === 0 || code.length > 6) {
        setError("In valid code");
        return;
      }
      if (authContext.mfaRequired)
        try {
          await authContext.sendMfaCode(email, code);
          setCode("");
          navigation("/register-complete");
        } catch (error: any) {
          setGenericErrorMessage(error.message);
        }
      else
        try {
          await authContext.sendCode(email, code);
          setCode("");
          navigation("/register-complete");
        } catch (error: any) {
          setGenericErrorMessage(error.message);
        }
    }
  };

  return (
    <div
      style={{
        ...backgroundStyle,
        ...backgroundStyleCenter,
      }}
    >
      <Paper elevation={1} style={{ ...paperStyle, maxWidth: "600px" }}>
        <div style={{ margin: "0px auto", width: "100%" }}>
          <Grid style={logoStyle}>
            <div className="logo-wrapper">
              <img src={verasightLogo} alt="Verasight Logo" />
            </div>
            <h3 style={h3Style}>Input 6-digit Code</h3>
            <p style={pStyle}>
              We send an SMS to your phone Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor.
            </p>
          </Grid>
          <TextField
            size="small"
            variant="outlined"
            placeholder="6-digit Code"
            type="password"
            fullWidth
            required
            style={inputStyle}
            onChange={(e: any) => setCode(e.target.value)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ ...btnStyle, marginTop: "60px" }}
            fullWidth
            onClick={sendCode}
            disabled={codeIsValid !== ""}
          >
            Verify
          </Button>
          <Typography style={TypographyStyle}>
            Didn't receive the email?
            <Link style={{ cursor: "pointer" }} onClick={onReSendCode}>
              Resend
            </Link>
          </Typography>
          <Typography style={TypographyStyle}>
            <Linker style={{ cursor: "pointer" }} to="/SignUp">
              {" "}
              Change Email
            </Linker>
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default VerifyEmail;
