import { Box, Container, Dialog, Typography } from "@mui/material";
import VeraLink from "../../../components/VeraLink";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import SurveysService from "../../../services/api/surveysService";
import { SurveyLimits, SurveyLinkTableParamEnum, SurveyStateEnum, SurveyType } from "../../../types/Survey";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { AppPaths, SearchParams } from "src/constants/url";
import { AxiosResponse } from "axios";
import { getErrorMessage } from "src/helpers/errors";
import SurveyCost from "src/components/SurveyCost/SurveyCost";
import PaperDefault from "../../../components/common/PaperDefault";
import ResearcherStudyForm from "../../../components/ResearcherStudyForm/ResearcherStudyForm";
import IntegrationGuideLink from "src/components/IntegrationGuideLink/IntegrationGuideLink";
import UserTargetingAuditList from "src/screens/admin/EditSurvey/components/UserTargetingAuditList";
import { AuthContext } from "src/contexts/authContext";
import { UserRoles } from "src/types/User";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function ResearcherEditSurvey({ readOnly }: { readOnly?: boolean }) {
  const { id: surveyId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const isAdmin = user?.role === UserRoles.SUPER_ADMIN;

  const [surveyData, setSurveyData] = useState<SurveyType>();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [limits, setLimits] = useState<SurveyLimits>();
  const [externalUrl, setExternalUrl] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>();
  const [editDisabled, setEditDisabled] = useState<boolean>(true);

  const setSurveyDataStates = async (res: AxiosResponse<SurveyType>) => {
    setTitle(res.data.payload?.name ?? "");
    setExternalUrl(res.data.externalSurveySourceData?.baseUrl ?? "");
    setLimits(res.data.limits);

    setIsLoading(false);
    setSurveyData(res.data);
    setEditDisabled(
      !res.data.state ? false : ![SurveyStateEnum.Draft, SurveyStateEnum.PaymentPending].includes(res.data.state)
    );
  };

  const fetchSurvey = useCallback(async () => {
    setIsLoading(true);
    if (!surveyId) return;

    SurveysService.getSurvey(surveyId).then((res) => {
      setSurveyDataStates(res);
    });
  }, [surveyId]);

  const updateSurvey = async (params: Partial<SurveyType> = {}) => {
    if (!surveyId) return;
    if (title !== surveyData?.title) {
      params.title = title;
    }
    if (externalUrl !== surveyData?.externalSurveySourceData?.baseUrl) {
      params.externalSurveySourceData = {
        baseUrl: externalUrl,
        parameters: surveyData?.externalSurveySourceData?.parameters?.length
          ? surveyData?.externalSurveySourceData?.parameters
          : [
              {
                orderId: 1,
                parameterName: "vsid",
                parameterType: SurveyLinkTableParamEnum.USER_ID,
                value: "",
                arguments: [],
              },
              {
                orderId: 2,
                parameterName: "surveyid",
                parameterType: SurveyLinkTableParamEnum.SURVEY_ID,
                value: "",
                arguments: [],
              },
            ],
      };
    }

    if (!title) {
      enqueueSnackbar(`You must fill in the required fields: title`, {
        variant: "error",
      });
      return;
    }

    if (Object.keys(params).length) {
      try {
        setIsLoading(true);
        await SurveysService.updateSurvey(surveyId, params);
      } catch (error: any) {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        return;
      } finally {
        setIsLoading(false);
      }
    }

    fetchSurvey();

    const time = new Date().getTime();
    enqueueSnackbar("Survey has been updated.", { variant: "success", key: `update_success_${time}` });
    setTimeout(() => {
      closeSnackbar(`update_success_${time}`);
    }, 5000);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      const secret = query.get(SearchParams.clientSecret);
      const paymentSessionId = query.get(SearchParams.sessionId);
      const successMessage = query.get(SearchParams.successMessage);
      const errorMessage = query.get(SearchParams.errorMessage);
      if (paymentSessionId && surveyId) {
        setIsLoading(true);
        SurveysService.confirmPayment(surveyId, { paymentSessionId })
          .then((res: AxiosResponse<SurveyType>) => {
            setSurveyDataStates(res);
            navigate(AppPaths.researcher.surveys.edit(surveyId), {
              replace: true,
            });
            enqueueSnackbar(`Your payment successfully completed.`, { variant: "success" });
          })
          .catch((err) => {
            enqueueSnackbar(`Failed to confirm survey payment, ${getErrorMessage(err)}`, { variant: "error" });
          })
          .finally(() => setIsLoading(false));
      } else if (secret) {
        setClientSecret(secret);
      } else if (successMessage) {
        enqueueSnackbar(successMessage, { variant: "success" });
      } else if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
  }, [location.search]);

  return (
    <Container
      sx={{
        marginTop: "60px",
      }}
    >
      <Box sx={{ marginBottom: "2rem" }}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h6">
            <VeraLink to="/admin/surveys">Back</VeraLink>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "1rem", padding: "1rem" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IntegrationGuideLink />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <PaperDefault>
          <ResearcherStudyForm
            title={title}
            setTitle={setTitle}
            externalUrl={externalUrl}
            setExternalUrl={setExternalUrl}
            surveyId={surveyId}
            externalSurveyRedirectLinksData={surveyData?.externalSurveyRedirectLinksData}
            activationRedirects={surveyData?.activationRedirects}
            state={surveyData?.state}
            readOnly={readOnly}
          />
          {readOnly || editDisabled ? null : (
            <Box display="flex" justifyContent="space-evenly" pt={5}>
              <button
                disabled={isLoading}
                type="button"
                onClick={() => updateSurvey({ limits: { ...limits!, discount: undefined, _id: undefined } })}
                className="last-container__button"
                data-testid="btn-submit"
              >
                {isLoading ? "Loading..." : "Save Information"}
              </button>
              <button
                hidden={surveyData?.state !== SurveyStateEnum.Draft}
                disabled={
                  isLoading ||
                  surveyData?.activationRedirects?.filter(
                    (redirect) => !redirect.activationLink && ["1", "2"].includes(redirect.vsref)
                  )?.length !== 2
                }
                type="button"
                onClick={() => updateSurvey({ active: true })}
                className="last-container__button"
                style={{ maxWidth: "300px" }}
                data-testid="btn-start-collection"
              >
                {isLoading ? "Loading..." : "Begin Collecting Survey Responses"}
              </button>
            </Box>
          )}
          <Box pt={5}>
            <SurveyCost
              readOnly
              key={`${surveyData?.limits?.discount}:${surveyData?.limits?._id}`}
              limits={surveyData?.limits}
              setLimits={setLimits}
              id={surveyData?._id}
              state={surveyData?.state}
            />
          </Box>
          {isAdmin && surveyData?.targetingTags?.length ? (
            <Box pt={5}>
              <UserTargetingAuditList tagIds={surveyData?.targetingTags ?? []} />
            </Box>
          ) : null}
        </PaperDefault>
      </Box>
      <Dialog fullWidth onClose={() => setClientSecret(undefined)} open={!!clientSecret}>
        {clientSecret ? (
          <Container sx={{ p: 3 }}>
            <div data-testid="checkout-form" id="checkout">
              <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          </Container>
        ) : null}
      </Dialog>
    </Container>
  );
}
