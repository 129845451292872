import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DateRangeWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

type Props = {
  label?: string;
  dateAfter: string | null;
  setDateAfter: (arg: string | null) => void;
  dateBefore: string | null;
  setDateBefore: (arg: string | null) => void;
};

const DateFilter = ({ label, dateAfter, setDateAfter, dateBefore, setDateBefore }: Props) => {
  return (
    <Box>
      <p className="option-title">{label ?? "Date Range Filter"}</p>

      <DateRangeWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={dateAfter && new Date(dateAfter)}
            onChange={(newValue: any) => {
              setDateAfter(newValue);
            }}
          />
        </LocalizationProvider>

        <span>-</span>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={dateBefore && new Date(dateBefore)}
            onChange={(newValue: any) => {
              setDateBefore(newValue);
            }}
          />
        </LocalizationProvider>
      </DateRangeWrapper>
    </Box>
  );
};

export default DateFilter;
