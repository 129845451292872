import { Button, Grid, OutlinedInput, Paper, Snackbar } from "@mui/material";
import {
  backgroundStyle,
  backgroundStyleCenter,
  btnStyle,
  inputStyle,
  logoStyle,
  paperStyle,
  pStyle,
  notice,
} from "../../styles/auth.styles";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import { useState } from "react";
import SnackBarClose from "../../components/common/SnackbarClose";
import { useNavigate } from "react-router-dom";
import InvitationsService from "src/services/api/invitationsService";
import { getErrorMessage } from "src/helpers/errors";

const ResearcherSignup = () => {
  const [invitationCode, setInvitationCode] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const navigate = useNavigate();

  const onOutlinedInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      await signup();
    }
  };

  const signup = async () => {
    if (!formValid()) {
      setSnackbarMessage("Please enter an invitation code.");
      setSnackbarOpen(true);
      return;
    }
    try {
      await InvitationsService.validate(invitationCode);
      navigate("/register?researcherInviteCode=" + invitationCode);
    } catch (err) {
      const message = getErrorMessage(err as Error, "Failed to validate the invite code");
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }
  };

  const formValid = (): boolean => {
    return invitationCode !== "";
  };

  return (
    <div style={{ ...backgroundStyle, ...backgroundStyleCenter }}>
      <Paper
        style={{
          ...paperStyle,
          boxShadow: "23px 32px 50px rgba(0, 0, 0, 0.12)",
          maxWidth: "600px",
        }}
      >
        <div style={{ margin: "0px auto", width: "100%" }}>
          <Grid style={logoStyle}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <div style={notice}>
            Welcome to Verasight! You've been invited to join our Researcher Partner program! Please enter your
            invitation code here.
          </div>
          <p style={pStyle}>Invitation Code</p>
          <OutlinedInput
            data-testid="input-invite-code"
            size="medium"
            placeholder="Invitation Code"
            fullWidth
            required
            value={invitationCode || ""}
            error={invitationCode === ""}
            style={inputStyle}
            onChange={(e: any) => setInvitationCode(e.target.value)}
            onKeyDown={onOutlinedInputKeyDown}
          />
          <Button
            data-testid="btn-submit"
            type="submit"
            variant="outlined"
            style={{
              ...btnStyle,
              backgroundColor: "white",
              color: "#5F6F74",
              border: "1px solid rgba(95, 111, 116, 1)",
              marginTop: "20px",
            }}
            fullWidth
            onClick={signup}
          >
            Sign Up
          </Button>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={<SnackBarClose handleClose={() => setSnackbarOpen(false)} />}
      />
    </div>
  );
};

export default ResearcherSignup;
