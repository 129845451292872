import "./ContactUsButton.scss";
import contactUsIcon from "src/assets/icon-contact-us.svg";

import { MouseEventHandler } from "react";
type Props = {
  onClick: MouseEventHandler<any> | undefined;
};
const ContactUsButton = ({ onClick }: Props) => {
  return (
    <button 
      type="button" 
      title="Need Help?"
      aria-label="Need Help?"
      data-testid="contact-us-button"
      className="contact-us-button" 
      onClick={onClick}
    >
      <img src={contactUsIcon} alt="ContactUs" />
    </button>
  );
};

export default ContactUsButton;
