import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { isCaptchaEnabled } from "src/helpers/generic";

interface Props {
  readonly setRecaptchaToken: (token?: string) => void;
  readonly getResetRef?: (reset: () => void) => void;
}

export default function ReCaptcha({ setRecaptchaToken, getResetRef }: Props) {
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  if (!isCaptchaEnabled()) {
    setRecaptchaToken("DISABLED");
    return null;
  }

  const resetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset();
      setRecaptchaToken(undefined);
    }
  };

  if (getResetRef) {
    getResetRef(resetCaptcha);
  }

  const verifyCaptcha = async (token: string | null) => {
    if (captchaRef.current) {
      token = captchaRef.current.getValue();
      try {
        if (token?.length) {
          setRecaptchaToken(token);
        } else {
          resetCaptcha();
        }
      } catch (error) {
        resetCaptcha();
      }
    }
  };

  const onCaptchaExpired = () => {
    resetCaptcha();
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_CAPTCHA_SITE_ID ?? ""}
      ref={captchaRef}
      onChange={verifyCaptcha}
      onExpired={onCaptchaExpired}
      style={{ marginTop: "30px" }}
    />
  );
}
