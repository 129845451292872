import { Typography } from "@mui/material";
const baseUrl = "https://www.verasight.io";

const getMailTo = () => <a href={`mailto:${process.env.REACT_APP_SENDER_EMAIL}`}>{process.env.REACT_APP_SENDER_EMAIL}</a>;
export const faqs = [
  {
    question: "What do I do if there are no surveys available?",
    answer: (
      <Typography>
        Surveys are typically posted one or two times per week, and you'll be notified via email when they are available. We aim for quality over quantity, offering higher incentives for each survey.
      </Typography>
    ),
  },
  {
    question: "When can I cash out my rewards?",
    answer: (
      <Typography>
        Once you accumulate 10,000 points, you can cash out your rewards. Choose between PayPal, Venmo, or gift cards for redemption.
      </Typography>
    ),
  },
  {
    question: "What happens if I didn't receive my cash out?",
    answer: (
      <Typography>
        If you experience a delay or bug with your cash out, contact us with the phone number/email used and the cash-out method.
      </Typography>
    ),
  },
  {
    question: "What are partner surveys?",
    answer: (
      <Typography>
        Partner surveys are opportunities to take surveys with companies outside of Verasight and still receive
        Verasight rewards. <b>Verasight is not able to monitor the content or length of these survey taking experiences.</b> If you haven't
        received points for partner surveys within 1 business day, please let us know. In addition, partner surveys are
        optional, and the full amount of points may not be rewarded even if you completed a lengthy pre-qualification
        questionnaire prior to taking the survey.
      </Typography>
    ),
  },
  {
    question: "Did I get points for my survey?",
    answer: (
      <Typography>
        To check if you've been credited, review the "Activity" tab of your account profile.
      </Typography>
    ),
  },
  {
    question: "How can I update my account email?",
    answer: (
      <Typography>If you need to update your account email, please contact us directly at {getMailTo()}</Typography>
    ),
  },
  {
    question: "How can I manage my account details, change passwords, or review completed surveys?",
    answer: (
      <Typography>
        Navigate to the "cash out" option located in the top left corner to manage your account details. For password changes, navigate to the "Security" tab. To review completed surveys, use the "Activity" tab.
      </Typography>
    ),
  },
  {
    question: "How do I ensure instant payment during cash out?",
    answer: (
      <Typography>
        Make sure the email address or phone number on your account is correct to guarantee instant payment. If you have
        an issue with the cash out process, please contact us directly at {getMailTo()} In most cases, a cash out will
        process immediately, but over holidays and weekends, certain banks may cause a slight delay.
      </Typography>
    ),
  },
  {
    question: "Can I change my cash out information?",
    answer: (
      <Typography>
        Yes, you can change your contact information at cash out. Click "No" if the displayed information doesn't match your desired email address or phone number.
      </Typography>
    ),
  },
  {
    question: "I'm seeing a \"user not authenticated\" error in survey experience:",
    answer: (
      <Typography>
        If you encounter this issue, try clearing your browser cookies/cache or use an incognito/private browser window
        before logging in. Refresh the page using Ctrl+Shift+R (on PC) or Cmd+Shift+R (on Mac). If you continue to have
        issues with the survey taking process, please contact us directly at {getMailTo()}
      </Typography>
    ),
  },
  {
    question: "How can I access the Verasight Community platform?",
    answer: (
      <Typography>
        You can access the Verasight Community platform from anywhere using your laptop, phone, or tablet to take surveys.
      </Typography>
    ),
  },
  {
    question: "Where can I find available surveys and their respective points?",
    answer: (
      <Typography>
        All available surveys, along with their corresponding points, are listed on the platform. Click the logo in the top right corner to see your available surveys.
      </Typography>
    ),
  },
  {
    question: "How do I keep track of my points?",
    answer: (
      <Typography>
        Your points are conveniently displayed in the top right corner of the platform. Earn a bonus of 1,000 points for every 10 surveys completed. To check if you've been credited for a completed survey, navigate to the "Activity" tab of your profile.
      </Typography>
    ),
  },
  {
    question: "What is the purpose of my survey responses?",
    answer: (
      <Typography>
        Your survey responses help companies design new products, enable news organizations to report accurately, and aid academic or government researchers in better understanding Americans.
      </Typography>
    ),
  },
  {
    question: "Do you have an app?",
    answer: (
      <Typography>
        Currently, we do not have an app, but we appreciate the suggestion and will pass it along to our team.
      </Typography>
    ),
  },
  {
    question: "Do you have a referral program?",
    answer: (
      <Typography>
        Currently, we do not have a referral program, but we are looking into launching this program in early 2024.
      </Typography>
    ),
  },
  {
    question: "What are the Verasight Community terms and conditions?",
    answer: (
      <Typography>
        Please read the full terms and conditions{" "}
        <a href={`${baseUrl}/terms`} target="_blank" rel="noopener noreferrer">here</a>. These are subject to change at any time.
      </Typography>
    ),
  },
];
