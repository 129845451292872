import React, { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import VeraLink from "../../../components/VeraLink";
import SurveyCard from "../../../components/SurveyCard/SurveyCard";
import SurveysService from "../../../services/api/surveysService";
import TargetingTagsService from "../../../services/api/targetingTagsService";
import "./EditSurvey.scss";
import RightColumnFields, {
  utmCampaignTargetingTagsAtom,
  utmCampaignTargetingTagExcludesAtom,
} from "./components/RightColumnFields";
import LeftColumnFields from "./components/LeftColumnFields";
import TargetUsersList from "./components/TargetUsersList";
import RawInformationBlock from "./components/RawInformationBlock";
import NotificationsBlock from "./components/NotificationsBlock";
import AvailableUsers from "./components/AvailableUsers";
import SourceTab from "../NewSurvey/components/SourceTab";
import RedirectLinksTab from "../NewSurvey/components/RedirectLinksTab";
import { RedirectLinksData, SourceData, RedirectPointsData, SurveyType } from "src/types/Survey";
import { getRedirectLinkPoints, getUtmCampaignTargetingTags } from "../../../helpers/surveyHelper";
import _ from "lodash";
import { TargetingTag } from "../../../types/TargetingTag";
import { useAtom } from "jotai";
import { NotificationSchedule, TargetingSurveyToUsersEnum } from "../../../types/Survey";
import CsvUploadProgress, { POLLING_INTERVAL } from "./components/CsvUploadProgress";
import { sleep } from "../../../helpers/generic";
import ScheduledNotification from "./components/NotificationsSchedule";
import TZPicker from "src/components/TZPicker/TZPicker";
import { PickersTimezone } from "@mui/x-date-pickers";
import SurveyNotificationLogs from "./components/SurveyNotificationLogs";
import PaperDefault from "../../../components/common/PaperDefault";
import { allAvailableUtmTagsAtom } from "src/atoms/utm-tags";
import { availableTargetingTagsAtom, targetingTagsAtom } from "src/atoms/targeting-tags";
import { UserRoles } from "src/types/User";
import UserTargetingAuditList from "src/screens/admin/EditSurvey/components/UserTargetingAuditList";
import { getErrorMessage } from "src/helpers/errors";

type SurveyParams = {
  active?: boolean;
  title?: string;
  image?: any;
  start?: string | null;
  end?: string | null;
  category?: string | null;
  tags?: string[];
  targetingTags?: string[];
  points?: number | null;
  spinnerProbability?: number | null;
  enableSpinner?: boolean | null;
  targetingSurveyToUsers?: string | null;
  externalSurveySourceData?: SourceData;
  externalSurveyRedirectLinksData?: RedirectLinksData;
  externalSurveyRedirectPointsData?: RedirectPointsData;
  targetUsersAfterSignupDate?: string | null;
  isLucidSurvey?: boolean;
  utmCampaignTargetingTag?: string;
  utmCampaignTargetingTags?: string[];
  utmCampaignTargetingTagExcludes?: string[];
  excludedTargetingTags?: string[];
  utmApplyingLogic?: UtmApplyingLogic;
  utmTargetUsersAfterSignupDate?: string;
  multiPointPrefixDisabled?: boolean;
};

let csvUploadStarted = false;

export enum UtmApplyingLogic {
  AND = "and",
  OR = "or",
}

enum SurveyTabViews {
  primary = "primary",
  targets = "targets",
  audits = "audits",
}

const EditSurvey = () => {
  const { id: surveyId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [surveyData, setSurveyData] = React.useState<SurveyType | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState<boolean>(false);
  const [oldStatus, setOldStatus] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [notificationSchedules, setNotificationSchedules] = React.useState<NotificationSchedule[]>();
  const [selectedNotificationSchedule, setSelectedNotificationSchedule] = React.useState<NotificationSchedule>();
  const [timezone, setTimeZone] = useState<PickersTimezone>("America/Los_Angeles");
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const [category, setCategory] = React.useState<string | null>(null);
  const [tags, setTags] = React.useState<string[]>([]);
  const [points, setPoints] = React.useState<number | null>(0);
  const [multiPointPrefixDisabled, setMultiPointPrefixDisabled] = React.useState<boolean>(true);
  const [upToPointAmount, setUpToPointAmount] = React.useState<number | null>(0);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [image, setImage] = React.useState<string | null>(null);
  const [targetUsers, setTargetUsers] = React.useState([]);
  const [notifyUsers, setNotifyUsers] = React.useState(false);
  const [usersFile, setUsersFile] = React.useState<File>();
  const [enableSpinner, setEnableSpinner] = React.useState(false);
  const [targetingSurveyToUsers, setTargetingSurveyToUsers] = React.useState<TargetingSurveyToUsersEnum | "">("");

  const [utmCampaignTargetingTags, setUtmCampaignTargetingTags] = useAtom(utmCampaignTargetingTagsAtom);
  const [utmCampaignTargetingTagExcludes, setUtmCampaignTargetingTagExcludes] = useAtom(
    utmCampaignTargetingTagExcludesAtom
  );

  const [availableTargetingTags, setAvailableTargetingTags] = useAtom(availableTargetingTagsAtom);
  const [targetingTags, setTargetingTags] = useAtom(targetingTagsAtom);
  const [, setAllAvailableUtmTags] = useAtom(allAvailableUtmTagsAtom);

  const [spinnerPercentage, setSpinnerPercentage] = React.useState<number | null>(0);
  const [showTargetingResults, setShowTargetingResults] = React.useState(false);
  const [tabView, setTabView] = React.useState<SurveyTabViews>(SurveyTabViews.primary);
  const [isSourceView, setIsSourceView] = React.useState<boolean>(true);
  const [showOnlyForExternalSurveys, setShowOnlyForExternalSurveys] = React.useState<boolean>(true);
  const [targetUsersAfterDate, setTargetUsersAfterDate] = React.useState<string | null>(null);
  const [isLucidSurvey, setIsLucidSurvey] = React.useState<boolean>(false);
  const [csvTag, setCsvTag] = React.useState<string>("");
  const [isResearcherSurvey, setIsResearcherSurvey] = useState(false);

  const [sourceData, setSourceData] = React.useState<SourceData>({
    baseUrl: "",
    parameters: [],
  });

  const [redirectLinksData, setRedirectLinksData] = React.useState<RedirectLinksData>({
    baseUrl: "",
    parameters: [],
  });

  const [redirectPointsData, setRedirectPointsData] = React.useState<RedirectPointsData>({
    parameters: [],
  });

  const [notificationsLocked, setNotificationsLocked] = useState<boolean>(false);

  const [excludeGlobalTags, setExcludeGlobalTags] = useState<boolean>(false);
  const [excludedTargetingTags, setExcludedTargetingTags] = useState<TargetingTag[]>([]);

  const [utmApplyingLogic, setUtmApplyingLogic] = useState<UtmApplyingLogic>(UtmApplyingLogic.AND);
  const [utmTargetUsersAfterSignupDate, setUtmTargetUsersAfterSignupDate] = useState<string>();

  const fetchAvailableTargetingTags = useCallback(async () => {
    const res = await TargetingTagsService.getSurveyTargetingTags(surveyId as string);
    const targetingTags: TargetingTag[] = res.data.docs;

    targetingTags.sort((tag1: TargetingTag, tag2: TargetingTag) => {
      const tag1Str = tag1.surveyId ? `aaa${tag1.tag}` : tag1.tag;
      const tag2Str = tag2.surveyId ? `aaa${tag2.tag}` : tag2.tag;
      return tag1Str.toLowerCase().localeCompare(tag2Str.toLowerCase());
    });

    return targetingTags;
  }, [surveyId]);

  const fetchSurvey = useCallback(async () => {
    setIsLoading(true);
    if (!surveyId) return;

    const fetchedTargetingTags = await fetchAvailableTargetingTags();

    setAvailableTargetingTags(
      fetchedTargetingTags.sort((a: TargetingTag, b: TargetingTag) => {
        if (a.surveyId && b.surveyId) {
          return a.tag.toLowerCase().localeCompare(b.tag.toLowerCase());
        } else if (a.surveyId) {
          return -1;
        } else if (b.surveyId) {
          return 1;
        } else {
          return a.tag.toLowerCase().localeCompare(b.tag.toLowerCase());
        }
      })
    );

    SurveysService.getSurvey(surveyId).then((res) => {
      setStatus(res.data.active);
      setOldStatus(res.data.active);
      if (res.data.title) setTitle(res.data.title);
      if (res.data.start) setStartDate(res.data.start);
      if (res.data.end) setEndDate(res.data.end);
      if (res.data.category) setCategory(res.data.category);
      if (res.data.points) setPoints(res.data.points ?? 0);
      if (res.data.tags) setTags(res.data.tags);

      setMultiPointPrefixDisabled(res.data.multiPointPrefixDisabled);

      setUtmCampaignTargetingTags(getUtmCampaignTargetingTags(res.data));
      setUtmCampaignTargetingTagExcludes(res.data.utmCampaignTargetingTagExcludes ?? []);
      if (res.data?.targetingTags?.length > 0) {
        setTargetingTags(fetchedTargetingTags.filter((tag) => res.data.targetingTags.includes(tag._id)));
      } else {
        setTargetingTags([]);
      }

      if (res.data.targetingSurveyToUsers) {
        setTargetingSurveyToUsers(res.data.targetingSurveyToUsers);
      }
      if (res.data.image) setImage(res.data.image);
      if (res.data.enableSpinner != null) setEnableSpinner(res.data.enableSpinner);
      if (res.data.spinnerProbability) setSpinnerPercentage(res.data.spinnerProbability * 100);
      if (res.data.shouldNotifyUsers) setNotifyUsers(res.data.shouldNotifyUsers);
      if (res.data.externalSurveySourceData) {
        setSourceData(res.data.externalSurveySourceData);
      }
      if (res.data.externalSurveyRedirectLinksData) {
        setRedirectLinksData(res.data.externalSurveyRedirectLinksData);
      }
      if (res.data.externalSurveyRedirectPointsData) {
        setRedirectPointsData(res.data.externalSurveyRedirectPointsData);
      }
      if (res.data.targetUsersAfterSignupDate) {
        setTargetUsersAfterDate(res.data.targetUsersAfterSignupDate);
      }
      if (res.data.isLucidSurvey) {
        setIsLucidSurvey(res.data.isLucidSurvey);
      }
      if (Array.isArray(res.data.notificationSchedules)) {
        setNotificationSchedules(res.data.notificationSchedules);
      }
      setShowOnlyForExternalSurveys(res.data.source !== "external");

      if (!!res.data?.excludedTargetingTags?.length) {
        setExcludeGlobalTags(true);
        setExcludedTargetingTags(
          fetchedTargetingTags.filter((tag) => res.data.excludedTargetingTags.includes(tag._id))
        );
      } else {
        setExcludeGlobalTags(false);
      }

      if (res.data?.utmApplyingLogic) {
        setUtmApplyingLogic(res.data.utmApplyingLogic);
      }
      if (res.data?.utmTargetUsersAfterSignupDate) {
        setUtmTargetUsersAfterSignupDate(res.data?.utmTargetUsersAfterSignupDate);
      }
      if (res.data.createdByRole) {
        setIsResearcherSurvey(res.data.createdByRole === UserRoles.RESEARCHER);
      }

      setIsLoading(false);
      setSurveyData(res.data);
    });
  }, [
    surveyId,
    setNotificationSchedules,
    setAvailableTargetingTags,
    setTargetingTags,
    fetchAvailableTargetingTags,
    setUtmCampaignTargetingTags,
  ]);

  useEffect(() => {
    const redirectLinkPoints = getRedirectLinkPoints(redirectPointsData);
    setUpToPointAmount(Math.max(...redirectLinkPoints));
  }, [points, redirectPointsData]);

  const onChangeSpinnerPercentage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setSpinnerPercentage(null);
      return;
    }
    const probability = +e.target.value.replace(/[^0-9.]/g, "");
    if (0 <= probability && probability <= 100) {
      setSpinnerPercentage(probability);
    } else {
      setSpinnerPercentage(100);
    }
  };

  const publish = async () => {
    await updateSurvey();
    setCsvTag("");
  };

  const cancelScheduledNotification = async (scheduleId: string) => {
    if (surveyId) {
      await SurveysService.cancelScheduledNotification(surveyId, scheduleId);
      await fetchSurvey();
    }
  };

  const updateSurvey = async (manualStatus?: boolean) => {
    if (!surveyId) return;

    if (selectedFile) {
      if (selectedFile.size > 1000000) {
        enqueueSnackbar("The selected image is above 1MB", { variant: "error" });
        return;
      }
      let formData = new FormData();
      formData.append("image", selectedFile);
      SurveysService.updateSurveyImage(surveyId, formData);
    } else if (image !== surveyData?.image) {
      SurveysService.removeSurveyImage(surveyId);
    }

    let params: SurveyParams = {};
    if (status !== surveyData?.active) {
      params.active = status;
    }
    if (manualStatus) {
      params.active = manualStatus;
    }
    if (title !== surveyData?.title) {
      params.title = title;
    }
    if (startDate !== surveyData?.start) {
      params.start = startDate;
    }
    if (endDate !== surveyData?.end) {
      params.end = endDate;
    }
    if (category !== surveyData?.category) {
      params.category = category;
    }
    if (tags !== surveyData?.tags) {
      params.tags = tags;
    }
    if (targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG) {
      let targetingTagsId = targetingTags
        .filter((targetingTag) => targetingTag._id !== "NEW_TAG")
        .map((targetingTag) => targetingTag._id ?? "");
      if (targetingTagsId.toString() !== surveyData?.targetingTags?.toString()) {
        params.targetingTags = targetingTagsId;
      }
    }
    if (targetingSurveyToUsers !== surveyData?.targetingSurveyToUsers) {
      params.targetingSurveyToUsers = targetingSurveyToUsers;
    }
    if (points !== surveyData?.points) {
      params.points = points;
    }
    if (spinnerPercentage != null && spinnerPercentage / 100 !== surveyData?.spinnerProbability) {
      params.spinnerProbability = spinnerPercentage / 100;
    }
    if (enableSpinner !== surveyData?.enableSpinner) {
      params.enableSpinner = enableSpinner;
    }
    if (targetUsersAfterDate !== surveyData?.targetUsersAfterSignupDate) {
      params.targetUsersAfterSignupDate = targetUsersAfterDate;
    }
    if (multiPointPrefixDisabled !== surveyData?.multiPointPrefixDisabled) {
      params.multiPointPrefixDisabled = multiPointPrefixDisabled;
    }
    // always include the source/redirect table data as it's too hard to compare them to prev values
    params.externalSurveySourceData = sourceData;
    params.externalSurveyRedirectLinksData = redirectLinksData;
    params.externalSurveyRedirectPointsData = redirectPointsData;
    params.isLucidSurvey = isLucidSurvey;
    params.utmCampaignTargetingTags = utmCampaignTargetingTags;
    params.utmCampaignTargetingTagExcludes = utmCampaignTargetingTagExcludes;
    params.utmApplyingLogic = utmApplyingLogic;
    params.utmTargetUsersAfterSignupDate = utmTargetUsersAfterSignupDate;

    const excludedTargetingTagsIds = excludedTargetingTags.map((tag) => tag._id);
    if (!_.isEqual(excludedTargetingTagsIds, surveyData?.excludedTargetingTags)) {
      params.excludedTargetingTags = excludedTargetingTagsIds;
    }

    if (!title) {
      enqueueSnackbar(`You must fill in the required fields: title`, {
        variant: "error",
      });
      return;
    }
    try {
      if (Object.keys(params).length) {
        await SurveysService.updateSurvey(surveyId, params);
        if (oldStatus !== status || manualStatus) {
          fetchSurvey();
        }
      }

      await updateTargetUsers();

      setUsersFile(undefined);
      fetchSurvey();

      const time = new Date().getTime();
      enqueueSnackbar("Survey has been updated.", { variant: "success", key: `update_success_${time}` });
      setTimeout(() => {
        closeSnackbar(`update_success_${time}`);
      }, 5000);
    } catch (err) {
      enqueueSnackbar(`Error updating survey: ${getErrorMessage(err as any)}`, {
        variant: "error",
      });
    }
  };

  const updateTargetUsers = async () => {
    if (!surveyId) return;

    const notifyFormData = new FormData();
    if (targetingSurveyToUsers === TargetingSurveyToUsersEnum.ALL) {
      notifyFormData.append("targetAllUsers", "true");
    } else if (targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG) {
      notifyFormData.append("targetingTags", "true");

      if (!_.isEmpty(csvTag) && usersFile) {
        notifyFormData.append("targetUsers", usersFile);
        notifyFormData.append("csvTag", csvTag);
      }
    } else {
      return;
    }

    try {
      const res = await SurveysService.uploadUsersFile(surveyId, notifyFormData);
      setTargetUsers(res.data.targetUsers);
      startPolling();
    } catch (err: any) {
      let validEmails = ``;
      let invalidEmails = ``;
      if (err.response?.data?.validEmails?.length) {
        validEmails = `valid emails: ` + err.response?.data?.validEmails.join(", ");
      }
      if (err.response?.data?.invalidEmails?.length) {
        invalidEmails = `invalid emails: ` + err.response?.data?.invalidEmails.join(", ");
      }
      const message = err.response?.data?.message || err.message;

      enqueueSnackbar(
        <Typography variant="subtitle2">
          {message}
          <br />
          {validEmails}
          <br />
          {invalidEmails}
        </Typography>,
        {
          variant: "error",
          persist: true,
          key: "uploadUsersFileError",
        }
      );
      setTimeout(() => {
        closeSnackbar(`uploadUsersFileError`);
      }, 10000);
    }
  };

  React.useEffect(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  useEffect(() => {
    TargetingTagsService.getUtmTargetingTags().then((res) => {
      setAllAvailableUtmTags(res.data);
    });
  }, []);

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImage(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setSelectedFile(null);
    setImage(null);
  };

  const changeSourceView = () => {
    setIsSourceView(!isSourceView);
  };

  useEffect(() => {
    if (!surveyData) return;

    const targetingTypeChanged = targetingSurveyToUsers !== surveyData.targetingSurveyToUsers;

    const targetingTagsChanged = !_.isEqual(
      targetingTags.map((targetingTag) => targetingTag._id).sort(),
      surveyData.targetingTags.sort()
    );

    const excludedTargetingTagsChanged =
      (surveyData.excludedTargetingTags &&
        !_.isEqual(excludedTargetingTags?.map((tag) => tag._id).sort(), surveyData.excludedTargetingTags?.sort())) ||
      (!surveyData.excludedTargetingTags && excludedTargetingTags.length > 0);

    const utmCampaignTagChanged =
      !_.isEqual(getUtmCampaignTargetingTags(surveyData), utmCampaignTargetingTags) ||
      !_.isEqual(surveyData.utmCampaignTargetingTagExcludes, utmCampaignTargetingTagExcludes);

    const locked =
      targetingTypeChanged || targetingTagsChanged || excludedTargetingTagsChanged || utmCampaignTagChanged;

    setNotificationsLocked(locked);
  }, [targetingSurveyToUsers, targetingTags, surveyData, excludedTargetingTags, utmCampaignTargetingTags]);

  // for csv upload progress
  const [csvProgressOpen, setCsvProgressOpen] = useState<boolean>(false);
  const [csvProgress, setCsvProgress] = useState<number>(0);

  const updateCsvProgressStatus = useCallback(async () => {
    if (!surveyId) return false;

    const response = await SurveysService.getCsvUploadStatus(surveyId);

    if (!response.data || !response.data.csvUploading) {
      return false;
    }

    setCsvProgress(response.data.csvUploadProgress);
    csvUploadStarted = true;

    return true;
  }, [surveyId]);

  const startPolling = async () => {
    let polling = true;
    while (polling) {
      const status = await updateCsvProgressStatus();

      setCsvProgressOpen(status);
      polling = status;

      if (!polling && csvUploadStarted) {
        fetchSurvey();
        csvUploadStarted = false;
      }

      await sleep(POLLING_INTERVAL);
    }
  };

  useEffect(() => {
    startPolling();
  }, [updateCsvProgressStatus]);

  // For excluded tags
  useEffect(() => {
    if (!excludeGlobalTags) {
      setExcludedTargetingTags([]);
    }
  }, [excludeGlobalTags]);

  return (
    <Container
      sx={{
        marginTop: "60px",
      }}
    >
      <CsvUploadProgress open={csvProgressOpen} setOpen={setCsvProgressOpen} progress={csvProgress} />

      {showTargetingResults ? (
        <TargetUsersList targetUsers={targetUsers} setShowTargetingResults={setShowTargetingResults} />
      ) : isLoading || !surveyData ? (
        "Loading..."
      ) : (
        <>
          <Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <VeraLink to="/admin/surveys">Back</VeraLink>
              </Typography>
              {!showOnlyForExternalSurveys && (
                <Typography mr={5} variant="h6">
                  <VeraLink
                    state={{ originalSurvey: surveyData }}
                    to={`/admin/surveys/new?originalSurveyId=${surveyId}`}
                  >
                    Clone
                  </VeraLink>
                </Typography>
              )}
            </Box>

            <Typography variant="h5">{surveyData.payload.title ?? surveyData.payload.name}</Typography>

            {isResearcherSurvey && (
              <Typography variant="h6" className="warning-text">
                Researcher Survey
              </Typography>
            )}
          </Box>
          {showOnlyForExternalSurveys ? (
            ""
          ) : (
            <>
              <Box className="tab">
                <div className={`tab-title ${!isSourceView ? `tab-title__disabled` : ""}`} onClick={changeSourceView}>
                  Source
                </div>
                <div className={`tab-title ${isSourceView ? `tab-title__disabled` : ""}`} onClick={changeSourceView}>
                  Redirect Links
                </div>
              </Box>
              <div className={isSourceView ? "" : "hidden"}>
                <SourceTab
                  sourceData={sourceData}
                  setSourceData={setSourceData}
                  isLucidSurvey={isLucidSurvey}
                  setIsLucidSurvey={setIsLucidSurvey}
                  parametersTitle="Parameters"
                />
              </div>
              <div className={isSourceView ? "hidden" : ""}>
                <RedirectLinksTab
                  multiPointPrefixDisabled={multiPointPrefixDisabled}
                  setMultiPointPrefixDisabled={setMultiPointPrefixDisabled}
                  surveyId={surveyId}
                  redirectLinksData={redirectLinksData}
                  setRedirectLinksData={setRedirectLinksData}
                  redirectPointsData={redirectPointsData}
                  setRedirectPointsData={setRedirectPointsData}
                />
              </div>
            </>
          )}
          <Box className="tab">
            <div
              className={`tab-title ${tabView !== SurveyTabViews.primary ? `tab-title__disabled` : ""}`}
              onClick={() => setTabView(SurveyTabViews.primary)}
            >
              Survey Details
            </div>
            <div
              className={`tab-title ${tabView !== SurveyTabViews.targets ? `tab-title__disabled` : ""}`}
              onClick={() => setTabView(SurveyTabViews.targets)}
            >
              Targeted Users
            </div>
            <div
              className={`tab-title ${tabView !== SurveyTabViews.audits ? `tab-title__disabled` : ""}`}
              onClick={() => setTabView(SurveyTabViews.audits)}
            >
              User Target Audits
            </div>
          </Box>
          {tabView === SurveyTabViews.primary ? (
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                alignItems: "flex-start",
              }}
            >
              <PaperDefault>
                <form id="uploadForm" encType="multipart/form-data">
                  <RawInformationBlock surveyData={surveyData} />

                  <Divider sx={{ marginY: "24px" }} />

                  <Typography variant="body1" fontWeight="bold">
                    Configure Internal Parameters
                  </Typography>

                  <div style={{ marginTop: 8 }}>
                    <span>Max image size: 1MB</span>
                  </div>

                  {image ? (
                    <div className="upload-image-container">
                      <div className="image-wrapper">
                        <img src={image} alt="survey-cover" />
                      </div>
                      <Button variant="outlined" onClick={removeImage} className="remove-image-button">
                        Remove Image
                      </Button>
                    </div>
                  ) : (
                    <div className="upload-image-container">
                      <input
                        type="file"
                        accept="image/*"
                        id="upload-image-button"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                      />

                      <label htmlFor="upload-image-button" className="upload-image-label">
                        <FileUploadOutlinedIcon />
                        <p>Upload Image</p>
                      </label>
                    </div>
                  )}

                  <LeftColumnFields
                    tags={tags}
                    setTags={setTags}
                    points={points}
                    setPoints={setPoints}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                    title={title}
                    setTitle={setTitle}
                    category={category}
                    setCategory={setCategory}
                    enableSpinner={enableSpinner}
                    setEnableSpinner={setEnableSpinner}
                    onChangeSpinnerPercentage={onChangeSpinnerPercentage}
                    spinnerPercentage={spinnerPercentage}
                    status={status}
                    setStatus={setStatus}
                    saveSurveyChanges={updateSurvey}
                    isResearcherSurvey={isResearcherSurvey}
                    isLoading={isLoading}
                  />
                  <RightColumnFields
                    usersFile={usersFile}
                    setUsersFile={setUsersFile}
                    notifyUsers={notifyUsers}
                    setNotifyUsers={setNotifyUsers}
                    onChangeSpinnerPercentage={onChangeSpinnerPercentage}
                    spinnerPercentage={spinnerPercentage}
                    enableSpinner={enableSpinner}
                    setEnableSpinner={setEnableSpinner}
                    setTargetingTags={setTargetingTags}
                    targetingTags={targetingTags}
                    availableTargetingTags={availableTargetingTags}
                    setTargetingSurveyToUsers={setTargetingSurveyToUsers}
                    targetingSurveyToUsers={targetingSurveyToUsers}
                    publish={publish}
                    AdapterDateFns={AdapterDateFns}
                    targetUsersAfterDate={targetUsersAfterDate}
                    setTargetUsersAfterDate={setTargetUsersAfterDate}
                    csvTag={csvTag}
                    setCsvTag={setCsvTag}
                    excludeGlobalTags={excludeGlobalTags}
                    setExcludeGlobalTags={setExcludeGlobalTags}
                    excludedTargetingTags={excludedTargetingTags}
                    setExcludedTargetingTags={setExcludedTargetingTags}
                    utmApplyingLogic={utmApplyingLogic}
                    setUtmApplyingLogic={setUtmApplyingLogic}
                    utmTargetUsersAfterSignupDate={utmTargetUsersAfterSignupDate}
                    setUtmTargetUsersAfterSignupDate={setUtmTargetUsersAfterSignupDate}
                  />

                  <Divider sx={{ marginY: "24px" }} />
                  <NotificationsBlock
                    notificationsLocked={notificationsLocked}
                    status={status}
                    updateSurvey={updateSurvey}
                    surveyStartTime={startDate}
                    selectedNotificationSchedule={selectedNotificationSchedule}
                    onRefreshSurvey={() => {
                      setSelectedNotificationSchedule(undefined);
                      fetchSurvey();
                    }}
                  />

                  {notificationSchedules?.length ? (
                    <>
                      <Divider sx={{ marginY: "24px" }} />
                      <Box sx={{ display: "flex", columnGap: "10px" }}>
                        <Stack spacing={2}>
                          <Typography variant="caption" align="center">
                            Scheduled Notifications
                          </Typography>
                          <TZPicker onTZChange={setTimeZone} />
                          <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Template Used</TableCell>
                                  <TableCell>Email Sent To </TableCell>
                                  <TableCell>Scheduled Time</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {notificationSchedules?.map((schedule) => (
                                  <ScheduledNotification
                                    timezone={timezone}
                                    notificationSchedule={schedule}
                                    onEdit={() => setSelectedNotificationSchedule(schedule)}
                                    onDelete={() => cancelScheduledNotification(schedule._id)}
                                    targetingTags={targetingTags}
                                  />
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </Box>
                    </>
                  ) : null}
                  <Divider sx={{ marginY: "24px" }} />
                  <SurveyNotificationLogs targetingTags={targetingTags} surveyId={surveyId} />
                </form>
              </PaperDefault>
              <div className="preview-card-container">
                <Typography sx={{ marginBottom: "10px" }} variant="body1">
                  Preview
                </Typography>
                <SurveyCard
                  cardHref={surveyData?.externalUrl}
                  name={title}
                  image={image}
                  reward={points}
                  upToReward={multiPointPrefixDisabled ? undefined : upToPointAmount}
                  categories={tags}
                  completed={false}
                  isEditMode={true}
                  isPreview={true}
                />
              </div>
            </Box>
          ) : tabView === SurveyTabViews.targets ? (
            <AvailableUsers />
          ) : (
            <UserTargetingAuditList tagIds={surveyData.targetingTags} />
          )}
        </>
      )}
    </Container>
  );
};

export default EditSurvey;
