import { useState } from "react";
import { Box } from "@mui/material";
import { InvitationCodeStatusEnum } from "../../../types/InvitationCode";
import { InvitationCodeFiltersType } from "./InvitationsList";
import FilterWrapper from "../../../components/common/FilterWrapper";
import SearchWrapper from "../../../components/common/SearchWrapper";
import DateFilter from "src/components/common/DateFilter";
import { useDateFilter } from "src/hooks/useDatefilter";
import SelectionFilter from "src/components/common/SelectionFilter";

type InvitationCodesFilterProps = {
  setPage: (page: number) => void;
  setFilters: (payload: InvitationCodeFiltersType) => void;
  setSearch: (search: string) => void;
};

const InvitationCodesFilter = ({ setPage, setFilters, setSearch }: InvitationCodesFilterProps) => {
  const [openFilters, setOpenFilters] = useState(false);

  const [statuses, setStatuses] = useState<InvitationCodeStatusEnum[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
  const { dateAfter, dateBefore, setDateAfter, setDateBefore } = useDateFilter();

  const applyFilters = () => {
    setPage(1);
    setFilters({
      ...(statuses.length && { statuses }),
      ...(dateAfter && { createdAtAfterDate: new Date(dateAfter).toISOString() }),
      ...(dateBefore && { createdAtBeforeDate: new Date(dateBefore).toISOString() }),
    });
    setOpenFilters(false);
  };

  const clearFilters = () => {
    setStatuses([]);
    setFilters({});
    setPage(1);
    setOpenFilters(false);
  };

  const onSearchClick = () => {
    setSearchIsActive(!!searchValue);
    setSearch(searchValue);
  };

  const onClearSearch = () => {
    setSearchValue("");
    setSearchIsActive(false);
    setSearch("");
  };

  return (
    <div className="filters-container">
      <FilterWrapper
        openFilters={openFilters}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
        setOpenFilters={setOpenFilters}
      >
        <Box>
          <SelectionFilter<InvitationCodeStatusEnum>
            items={Object.values(InvitationCodeStatusEnum)}
            selectedItems={statuses}
            setSelectedItems={setStatuses}
            label="Action"
          />
        </Box>

        <Box>
          <DateFilter
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            setDateAfter={setDateAfter}
            setDateBefore={setDateBefore}
            label="Action Performed At"
          />
        </Box>
      </FilterWrapper>

      <SearchWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearchClick={onSearchClick}
        onClearSearch={onClearSearch}
        searchIsActive={searchIsActive}
      />
    </div>
  );
};

export default InvitationCodesFilter;
