import { Link } from "react-router-dom";

type Props = {
  text?: string;
};

export default function IntegrationGuideLink({ text }: Props) {
  return (
    <Link target="_blank" to="/self-serve-survey-integration-guide">
      {text ? text : "Verasight's Self-Serve Survey Integration Guide"}
    </Link>
  );
}
