import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSortableData(setPage: (value: number) => void) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [order, setOrder] = useState<"asc" | "desc">((searchParams.get("orderDir") as "asc" | "desc") || "desc");
  const [orderBy, setOrderBy] = useState<string>(searchParams.get("orderBy") || "_id");

  useEffect(() => {
    const params: Record<string, string | null> = {};
    for (const key of Array.from(searchParams.keys())) {
      params[key] = searchParams.get(key);
    }
    setSearchParams({ ...params, orderBy, orderDir: order });
  }, [orderBy, order, searchParams, setSearchParams]);

  const handleRequestSort = (_event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  return { orderBy, order, handleRequestSort };
}
