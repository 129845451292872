export const dowloadCsv = async (fn: () => Promise<BlobPart>, title?: string) => {
  const blobPart = await fn();
  const blob = new Blob([blobPart], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", title ?? "verasight-download.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
