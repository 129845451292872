export type EmailTemplate = {
  id: string;
  name: string;
  html?: string;
  subject?: string;
};

export type EmailContactUs = {
  subject: string;
  message: string;
};

export enum EmailTemplateParameterEnum {
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  loginUrl = "loginUrl",
}
